@import "../../assets/styles/base";

#timeout-warning {
  font-size: 18px;

  @include themify($themes) {
    color: themed("timeout-warning");
  }

  #countdown {
    font-size: 72px;
    font-weight: 600;
    text-align: center;
  }

  #logged-out {
    margin-top: 5px;

    #timeout-warning-image {
      padding-left: 10px;
    }
  }
}

#logout-button {
  @include themify($themes) {
    background-color: themed("timeout-warning-logout-button-bg");
  }
}

#logout-button:focus,
#logout-button:hover {
  text-decoration: underline;

  @include themify($themes) {
    background-color: themed("timeout-warning-logout-button-focus-bg");
  }
}
