@import "../../../assets/styles/base";

$font-size-infographic-body: 24px;
$font-size-small: 30px;
$font-size-medium: 40px;
$font-size-large: 50px;
$font-size-x-large: 60px;

$computer-font-size-infographic-body: 22px;
$computer-font-size-small: 25px;
$computer-font-size-medium: 30px;
$computer-font-size-large: 35px;
$computer-font-size-x-large: 40px;

$mobile-font-size-infographic-body: 20px;
$mobile-font-size-small: 25px;
$mobile-font-size-medium: 30px;
$mobile-font-size-large: 35px;
$mobile-font-size-x-large: 40px;

#infographic-template {
  padding: 40px;
  margin: auto;
  position: relative;
  font-size: $font-size-infographic-body;
  line-height: 1.3;

  .no-padding {
    padding: 0 !important;

    &.heading {
      & > .image-header-text-wrapper {
        padding: 0 !important;
        & > .heading-image {
          padding: 0 !important;
        }
      }
    }
  }

  ol,
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  .font-size-small {
    font-size: $font-size-small;
  }

  .font-size-medium {
    font-size: $font-size-medium;
  }

  .font-size-large {
    font-size: $font-size-large;
  }

  .font-size-x-large {
    font-size: $font-size-x-large;
  }

  & > .block {
    margin-top: -1px;

    &:first-child {
      padding-top: 40px;
    }

    &:last-child {
      padding-bottom: 40px;
    }
  }

  .styled-text {
    width: 100%;

    -ms-word-break: break-word;
    word-break: break-word;

    sup {
      font-size: 60%;
      top: -0.7em;
    }
  }

  .block {
    padding-top: 0;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    & > div:not(.block):not(.styled-text) {
      padding-left: 10px;
      padding-right: 10px;
    }

    background-repeat: no-repeat;
    background-size: cover;

    .row {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      .column {
        display: flex;
        flex: 1 1;
        flex-flow: column;

        & > .block {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .heading {
    height: 100%;
    .image-header-text-wrapper {
      height: 100%;
      display: flex;
      flex-flow: column;

      .heading-image {
        margin-bottom: 10px;

        &:only-child {
          align-items: center;
          flex-grow: 1;
        }
      }

      .main-header {
        font-size: $font-size-medium;
        text-align: center;
        flex-grow: 0;
        margin-bottom: 10px;
      }

      .intro-text {
        font-size: $font-size-infographic-body;
        flex-grow: 0;
      }

      & > div:last-child {
        margin-bottom: 0;
      }
    }
  }

  .image-statement {
    img {
      padding-top: 20px;
    }
    .styled-text {
      padding: 0 20px;
    }

    .main-header {
      font-size: $font-size-medium;
      text-align: center;

      &:not(:only-child) {
        margin-bottom: 10px;
      }
    }
    .image-header-text-wrapper {
      width: 100%;
      .intro-text {
        .column > div {
          width: 100%;
        }
        font-size: $font-size-infographic-body;
      }
    }

    .column {
      &.image {
        width: 33%;
        flex: auto;
      }

      &.image-stat-text {
        width: 66%;
        flex: auto;
      }

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .statement {
    padding: 5% 10%;
    padding-left: 10%;
    padding-right: 10%;
    .column {
      &.statement {
        font-size: $font-size-medium;
        width: 30%;
        flex: auto;
      }
      &.supporting-text {
        flex: auto;
        width: 70%;

        & > .styled-text {
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }
  }

  .column {
    .block {
      padding-left: 0;
      padding-right: 0;
    }
    .heading {
      .heading-image {
        padding-left: 40px;
        padding-right: 40px;
      }
      .main-header {
        font-size: $font-size-medium;
      }
      .intro-text {
        font-size: $font-size-infographic-body;
      }
    }

    .column-block {
      margin: 0 4px;
    }
  }

  .two-column {
    padding-left: 10px;
    padding-right: 10px;

    .list {
      padding-left: 30px;

      ul {
        .list {
          ul {
            margin-left: 0px;
          }
        }
      }
    }

    & > .row {
      > .column {
        & > div {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  .three-column {
    padding-left: 20px;
    padding-right: 20px;

    > .row {
      > .column {
        & > div {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  .page-break {
    padding: 0;
  }

  .sources {
    .sources-header {
      font-size: $font-size-medium;
    }
    .sources-list {
      padding-inline-start: 16px;
    }
  }

  .transition-border {
    padding: 0;
    height: 40px;
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
    margin-top: -2px;

    &.sawtooth-border {
      padding: 0;
      height: 36px;
      background-size: 0px 100%, 40px 40px, 40px 40px;
      background-position-x: initial, 2px, 1px;
      background-position-y: initial, 50%, 50%;
    }

    &.scallop-border {
      background-size: 50px 100px;
      padding: 0;
      height: 40px;
      background-position-x: initial, 0px, 0px;
      background-position-y: initial, 50%, 50%;
    }
  }

  .centered-text {
    padding-left: 15%;
    padding-right: 15%;

    & > .styled-text {
      padding: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .section {
    padding: 0;
    height: 100%;

    & > .block:not(.transition-border) {
      height: auto;
    }
  }

  .list {
    padding-left: 80px;
    padding-right: 80px;
    ul {
      .list {
        ul {
          margin-left: 20px;
        }
      }
    }
    .block {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;
    }

    .styled-text {
      padding: 0;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #infographic-template {
    font-size: $computer-font-size-infographic-body;

    .font-size-small {
      font-size: $computer-font-size-small;
    }

    .font-size-medium {
      font-size: $computer-font-size-medium;
    }

    .font-size-large {
      font-size: $computer-font-size-large;
    }

    .font-size-x-large {
      font-size: $computer-font-size-x-large;
    }

    .block {
      padding-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px;

      & > div:not(.block):not(.styled-text) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .page-break {
      padding: 0;
    }

    .two-column {
      padding-left: 10px;
      padding-right: 10px;

      & > .row {
        > .column {
          & > div {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }

    .three-column {
      padding-left: 5px;
      padding-right: 5px;

      > .row {
        > .column {
          & > div {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }

    .section {
      padding: 0;
    }

    .heading {
      .image-header-text-wrapper {
        .main-header {
          font-size: $computer-font-size-medium;
        }

        .intro-text {
          font-size: $computer-font-size-infographic-body;
        }
      }
    }

    .list {
      ul {
        .list {
          ul {
            margin-left: 5px;
          }
        }
      }
    }

    .image-statement {
      .main-header {
        font-size: $computer-font-size-medium;
      }
      .intro-text {
        font-size: $computer-font-size-infographic-body;
      }
    }

    .statement {
      .column {
        &.statement {
          font-size: $computer-font-size-medium;
        }
      }
    }

    .column {
      .heading {
        .main-header {
          font-size: $computer-font-size-medium;
        }
        .intro-text {
          font-size: $computer-font-size-infographic-body;
        }
      }
    }

    .sources {
      .sources-header {
        font-size: $computer-font-size-medium;
      }
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  #sba-main-row #infographic-template .column {
    padding-bottom: 0;
  }

  #infographic-template {
    font-size: $mobile-font-size-infographic-body;
    padding: 0;
    word-wrap: break-word;

    .no-padding {
      padding: 0 !important;
    }

    .font-size-small {
      font-size: $mobile-font-size-small;
    }

    .font-size-medium {
      font-size: $mobile-font-size-medium;
    }

    .font-size-large {
      font-size: $mobile-font-size-large;
    }

    .font-size-x-large {
      font-size: $mobile-font-size-x-large;
    }

    .block {
      padding: 0 20px 20px 20px;

      & > div:not(.block):not(.styled-text) {
        padding-left: 0px;
        padding-right: 0px;
      }

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      .row {
        flex-direction: column;
        .column {
          flex: 1;
        }
      }
    }

    .styled-text {
      padding-left: 5px;
      padding-right: 5px;
    }

    .page-break {
      padding: 0;
    }

    .heading {
      height: 100%;
      .image-header-text-wrapper {
        .main-header {
          font-size: $mobile-font-size-medium;
        }

        .intro-text {
          font-size: $mobile-font-size-infographic-body;
        }
      }
    }

    .list {
      ul {
        .list {
          ul {
            margin-left: 5px;
          }
        }
      }
    }

    .column {
      .heading {
        .main-header {
          font-size: $mobile-font-size-medium;
        }
        .intro-text {
          font-size: $mobile-font-size-infographic-body;
        }
      }
    }

    .block.two-column,
    .block.three-column {
      padding-left: 0px;
      padding-right: 0px;

      & > div.row {
        padding-left: 0px;
        padding-right: 0px;
        > .column {
          padding-bottom: 10px;
          & > div:not(.ssection) {
            padding-left: 20px;
            padding-right: 20px;
          }

          .list {
            padding-left: 30px;

            ul {
              .list {
                ul {
                  margin-left: 0px;
                }
              }
            }
          }
        }
      }
    }

    .section {
      padding: 0;
    }

    .statement-text {
      .column {
        &.statement {
          width: 90%;
          text-align: center;
        }
        &.supporting-text {
          width: 90%;
        }
      }
    }

    .image-statement {
      .styled-text {
        padding: 0 5px;
      }
      .main-header {
        font-size: $mobile-font-size-medium;
      }
      .intro-text {
        font-size: $mobile-font-size-infographic-body;
      }
      .column {
        &.image {
          width: unset;
          flex: auto;
        }

        &.image-stat-text {
          &:not(:only-child) {
            margin-top: 20px;
          }

          width: unset;
          flex: auto;
        }

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .sources {
      .sources-header {
        font-size: $mobile-font-size-medium;
      }
    }

    .transition-border {
      background-repeat: repeat-x;
      background-position: center;
      background-size: contain;
    }
  }
}

@media all and (-ms-high-contrast: none) {
  #infographic-template {
    padding: 0;
    word-wrap: break-word;

    .no-padding {
      padding: 0 !important;
    }

    img {
      min-width: auto;
      flex-grow: 0;
      align-self: flex-start;
      width: auto;
      flex-shrink: 0;
      max-width: 100%;
    }

    .block {
      .row {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        min-width: auto;
        flex-shrink: 0;
        width: 100%;

        .column {
          display: flex;
          flex: 1 1;
          flex-flow: column;
          min-width: auto;
          flex-shrink: 0;

          & > .block {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            min-width: auto;
            flex-shrink: 0;
            width: 100%;
          }
        }
      }
    }

    .section {
      padding: 0;
    }

    .statement-text {
      .column {
        &.statement {
          width: 90%;
          text-align: center;
        }
        &.supporting-text {
          width: 90%;
        }
      }
    }

    .image-statement {
      .column {
        &.image {
          width: 33%;
          flex: auto;
        }

        &.image-stat-text {
          width: 66%;
          flex: auto;
        }

        .image-header-text-wrapper {
          width: 100%;
        }

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .transition-border {
      background-repeat: repeat-x;
      background-position: center;
      background-size: auto;
    }
  }
}

@media all and (max-width: $tablet-width) and (-ms-high-contrast: none) {
  #infographic-template {
    padding: 0;
    word-wrap: break-word;

    .no-padding {
      padding: 0 !important;
    }

    .font-size-x-large {
      font-size: $mobile-font-size-x-large;
    }

    .block {
      padding: 0 20px 20px 20px;

      background-repeat: no-repeat;
      background-size: contain;
      display: block;

      .row {
        flex-direction: unset;
        display: block;
        .column {
          display: block;
          flex-direction: unset;
        }
      }
    }

    .heading {
      height: 100%;
      .image-header-text-wrapper {
        display: block !important;
      }
    }

    .section {
      padding: 0;
    }

    .statement-text {
      .column {
        &.statement {
          width: 90%;
          text-align: center;
        }
        &.supporting-text {
          width: 90%;
        }
      }
    }

    .image-statement {
      .column {
        &.image {
          width: unset;
          flex: auto;
        }

        &.image-stat-text {
          width: unset;
          flex: auto;
        }

        display: block;
        align-items: center;
        justify-content: center;
      }
    }

    .transition-border {
      background-repeat: repeat-x;
      background-position: center;
      background-size: auto;
    }
  }
}
