@import "../../assets/styles/base";

.ui.footer-logo {
  background-color: $sba-navy;
  color: $sba-white;
  padding-top: 50px;

  a .icon {
    margin-right: 10px;
    color: $sba-white;

    &:hover,
    &:focus {
      text-decoration: none;

      @include themify($themes) {
        color: themed("footer-link-active-color");
      }
    }
  }

  #socials {
    padding-top: 40px;
  }

  #sba-footer-logo {
    display: inline;
    margin-right: 25px;

    @media only screen and (max-width: $mobile-width) {
      float: left;
    }
  }
}

.ui.inverted.footer {
  background-color: $sba-light-grey;
  padding: 50px 0;

  h3.ui.inverted.header {
    @include themify($themes) {
      color: themed("footer-header-color");
    }
  }

  .ui.inverted.link.list .item,
  .ui.inverted.link.list .item a:not(.ui),
  .ui.inverted.link.list a.item {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.13rem;

    @include themify($themes) {
      color: themed("footer-link-color");
    }
  }

  a .icon {
    margin-right: 10px;
    color: $sba-white;

    &:hover,
    &:focus {
      text-decoration: none;

      @include themify($themes) {
        color: themed("footer-link-active-color");
      }
    }
  } 
}

@media only screen and (max-width: $mobile-width) {
  .ui.inverted.footer, .ui.footer-logo {
    padding: 50px 25px;
  }
}
