@import "../../assets/styles/base";

.ui.breadcrumb {
  .global-breadcrumb-section.active {
    &:hover {
      text-decoration: none;
    }

    font-weight: normal;
  }

  a.section.global-breadcrumb-section {
    @include themify($themes) {
      color: themed("global-breadcrumb-section-color");
    }
  }
}
