@import "../../assets/styles/base";

.search-page-parent {
  #add_filters {
    margin: 0;
    width: 160px;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .grid.search-page {
    padding: 0px;
    margin: 0px;
    display: inline;

    .search-results {
      min-height: 450px;
    }
  }

  button.applied-filter {
    padding-right: 10px;
    font-weight: normal;
    margin-top: 3px;

    @include themify($themes) {
      background-color: themed("search-button-applied-filter-bg");
      border: 1px solid themed("search-border-color");
    }

    .icon {
      margin-left: 10px !important;
    }

    &:hover,
    &:focus {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  button.applied-filter:focus,
  button.applied-filter:active,
  button.applied-filter:hover {
    @include themify($themes) {
      background-color: themed("search-button-applied-filter-bg");
    }
  }

  .search-page-header {
    box-shadow: $box-shadow-two;
    padding: 20px 0 !important;
    margin-top: -1rem;

    @include themify($themes) {
      background-color: themed("search-button-applied-filter-bg");
    }
  }

  .search-page {
    .search-result-item {
      box-shadow: $box-shadow-two;
      border-radius: 7px;
      margin: 19px;
      min-height: 192px;

      @include themify($themes) {
        background-color: themed("search-item-bg");
        border: 1px solid themed("search-border-color");
      }
    }

    .search-result-inner {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .search-result-content {
      padding: 0 0 0 0;

      .search-result-title {
        margin-bottom: 0px;
      }

      .search-result-type {
        font-style: italic;
      }

      .search-result-description {
        padding-top: 8px;
      }
    }

    .placeholder,
    .placeholder .image.header:after,
    .placeholder .line,
    .placeholder .line:after,
    .placeholder > :before {
      @include themify($themes) {
        background-color: themed("body-background-color");
      }
    }

    .search-placeholder {
      max-width: inherit;
    }

    .ui.menu.pagination {
      a {
        @include themify($themes) {
          color: themed("search-pagination");
        }
      }

      a:hover.item,
      a:active.item {
        text-decoration: underline;

        @include themify($themes) {
          color: themed("search-pagination-a-active");
        }
      }
    }
  }

  #applied-filters-container {
    margin-left: 19px;
  }

  @media only screen and (max-width: $mobile-width) {
    #applied-filters-container {
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      white-space: nowrap;
      padding-left: 10px;

      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    #applied-filters-container::-webkit-scrollbar {
      display: none;
      height: 0;
    }

    .ui.grid > .row.search-page-header {
      box-shadow: none;
      padding: 6px 0 !important;

      @include themify($themes) {
        border-bottom: 1px solid themed("search-border-color");
      }

      .filters-column {
        padding-bottom: 0;
      }

      h1.ui.header {
        font-size: 26px;
        font-weight: bold;

        @include themify($themes) {
          color: themed("search-header");
        }
      }

      .ui.button#add_filters {
        width: 65px;
      }

      .ui.button.primary#add_filters {
        @include themify($themes) {
          color: themed("search-button-add-filters") !important;
        }

        &:active,
        &:hover,
        &:focus {
          text-decoration: none;

          @include themify($themes) {
            color: themed("search-button-add-filters") !important;
            background-color: themed(
              "search-button-add-filters-active-bg"
            ) !important;
          }
        }
      }
    }

    .search-results {
      .ui.stackable.grid > .column.row > .column {
        padding: 0px !important;
      }

      .ui.stackable.grid.facet-grid {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    h3.ui.header {
      font-size: 1.5rem;
    }

    .search-page {
      .search-result-item {
        border-left: none;
        border-right: none;
        border-radius: 0px;
        box-shadow: none;
        margin-top: 0px !important;
        padding-top: 10px;
        padding-bottom: 25px;
        padding-left: 16px;
        padding-right: 16px;
        min-height: 100px;

        @include themify($themes) {
          border-top: 1px solid themed("search-border-color");
          border-bottom: 1px solid themed("search-border-color");
        }
      }

      .search-result-content {
        padding: 5px 5px 5px 5px;

        .search-result-title {
          padding-bottom: 2px;
          padding-top: 5px;
        }
      }
    }

    .ui.image.search-result-image {
      display: none;
    }

    .ui.grid .ui.stackable.grid.search-result-item {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 0px;
    }
  }

  .facets-header {
    font-size: 18pt;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ui.header {
    margin-bottom: 15px;
    margin-top: 0px;
  }

  #search-facets {
    font-size: 18px;

    .div-hr {
      width: 100%;
      height: 0.01px;
      border-top-style: solid;
      border-left: none;
      border-right: none;
      border-bottom: none;
      display: block;

      @include themify($themes) {
        border-top: 1px solid themed("semantic-hr-color");
        color: themed("semantic-hr-color");
        border-color: themed("semantic-hr-color");
      }
    }

    #bottom-hr {
      margin-bottom: 10px;
    }

    @media only screen and (max-width: 767px) {
      .div-hr#top-hr {
        display: none;
      }
    }

    @media only screen and (min-width: 768px) {
      .div-hr#bottom-hr {
        display: none;
      }
    }

    .search-facet {
      width: 200px;
      display: inline-block;
      vertical-align: top;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 20px;

      @media only screen and (max-width: 1070px) {
        border-right: none !important;
        width: 95%;
      }

      @media only screen and (max-width: $mobile-width) {
        float: left;
        clear: left;
      }
    }

    .search-facet.border-right {
      margin-right: 20px;

      @include themify($themes) {
        border-right: 1px solid themed("semantic-hr-color");
      }

      @media only screen and (min-width: 768px) {
        min-width: 415px;
      }

      @media only screen and (max-width: 767px) {
        border-right: none;
      }
    }

    .facet-checkbox {
      cursor: pointer;
      padding-bottom: 2px;
      width: 200px;
      display: inline-block;
      line-height: 2;

      .checked {
        color: $sba-blue;

        @include themify($themes) {
          color: themed("search-facet-checkbox");
        }
      }
    }

    .facets-footer {
      padding-top: 10px;
      clear: left;

      #footer-buttons {
        float: right;
      }

      @media only screen and (min-width: 768px) {
        float: right;
      }

      button:hover {
        text-decoration: underline;
      }
    }

    button:focus {
      @include themify($themes) {
        border: solid 2px themed("outline-color");
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  #sba-main-row > .column > .content-window > .search-page-parent {
    padding: 0;
  }
}
