@import "../../assets/styles/base";

#registration-modal {
  .ui.message {
    padding: 0.8em 1.5em;
  }
}

.ui.success.message {
  width: 456px !important;
}

.reset-modal {
  &.bg-gray {
    margin: 2rem -3rem -3rem -3rem;
    padding: 2rem 3rem;
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;

    @include themify($themes) {
      background-color: themed("reset-modal-background");
    }
  }
  &.middle.aligned {
    padding: 1rem 0;
  }
}

.login-error-message {
  margin-bottom: 25px;
}
