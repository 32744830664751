@import "assets/styles/base";
@import "assets/styles/ie11-overrides";
@import "assets/styles/printing";
@import "assets/styles/modals";

body {
  margin: 0;
  padding: 0;
  font-family: $main-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include themify($themes) {
    background-color: themed("body-background-color");
    color: themed("body-color");
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: $body-font-size;
}

a {
  @include themify($themes) {
    color: themed("global-a-color");
  }
}

a:hover,
a:focus {
  text-decoration: underline;
}

hr {
  border: none;

  @include themify($themes) {
    border-top: 1px solid themed("global-hr-border");
  }
}

.ui.button {
  &.primary {
    @include themify($themes) {
      background-color: themed("global-button-primary-bg");
    }
  }

  &.secondary {
    @include themify($themes) {
      background-color: themed("global-button-secondary-bg");
      color: themed("global-button-secondary-color");
    }
  }

  &:hover,
  &:active,
  &:focus,
  &.primary:hover,
  &.secondary:hover,
  &.primary:active,
  &.secondary:active,
  &.primary:focus,
  &.secondary:focus {
    text-decoration: underline;

    @include themify($themes) {
      background-color: themed("global-active-bg");
      color: themed("global-active-color");
    }

    i {
      text-decoration: none;
    }
  }

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.modal {
  @media only screen and (max-width: $mobile-width) {
    width: 100% !important;
    height: 100% !important;
  }
}

.ui.progress .bar {
  min-width: 0;
}

[type="radio"] {
  margin-right: 10px;
}

::placeholder {
  @include themify($themes) {
    color: themed("global-placeholder-color") !important;
  }
}

a.card:focus,
a.ui.card:focus {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

/* Semantic UI has these classes, however they're only applicable to*/
/* grids, containers, rows and columns.*/
/* plus, there isn't any `mobile hidden`, `X hidden` class.*/
/* this snippet is using the same class names and same approach*/
/* plus a bit more but to all elements.*/
/* see https://github.com/Semantic-Org/Semantic-UI/issues/1114*/
/* Mobile */
@media only screen and (max-width: $mobile-width) {
  .ui.tiny.modal {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .eli-modal-close-button {
      position: relative;
      left: -15px;
    }

    .content-window > div > .ui.grid {
      margin: 0 2em;
    }
  }

  #sba-main-row {
    .column {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 0.5rem;
    }
  }

  #sba-main-row > .column > .content-window > div {
    padding: 0 0;
  }

  img {
    max-width: 100%;
  }
}
@media only screen and (max-width: $mobile-width) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }

  input[type="text"],
  input[type="number"],
  textarea {
    font-size: 16px !important;
  }
}
/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}
/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
}
/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}
/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen"]),
  [class*="tablet only"]:not([class*="widescreen"]),
  [class*="computer only"]:not([class*="widescreen"]),
  [class*="large screen only"]:not([class*="widescreen"]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}
