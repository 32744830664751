@import "../../assets/styles/base";

.leader-text-wrapper {
  color: $sba-dark-grey;
  &.grid {
    padding: 0 45px 0 45px;
    
    @media only screen and (max-width: $mobile-width) {
      padding: 0 1em;
    }
  }

  .row.leader-text-row {
    padding: 0px;
  }

  p {
    padding-bottom: 30px;
    white-space: pre-line;
  }
}
