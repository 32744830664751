@media print {
  body {
    overflow: visible !important;
    display: block !important;

    height: auto !important;

    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  .journey-content-row {
    display: block !important;

    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  .journey-content {
    .ui.centered.grid {
      display: block !important;
    }
  }

  #topic-content-view {
    display: block !important;

    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }
  .learning-event-container {
    display: block !important;

    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  .learning-event-wrapper {
    display: block !important;

    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  #infographic-template {
    & > .block {
      margin-top: 0px;
    }

    .transition-border {
      margin-top: 0px !important;
    }

    display: block !important;

    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;

    & > .block {
      display: block !important;

      overflow: visible !important;
      overflow-x: visible !important;
      overflow-y: visible !important;
    }
  }
  #sba-main-column {
    display: block !important;
  }

  #sba-main-row {
    display: block !important;
  }

  .ui.stackable.grid.sba {
    display: block !important;
  }

  .learning-event-wrapper {
    height: 100%;
    display: block !important;
  }

  .learning-event-body {
    display: block !important;
  }

  .button {
    display: none !important;
  }
  .header-menu {
    display: none !important;
  }

  .breadcrumb-header-grid {
    .breadcrumb-row {
      visibility: hidden !important;
      height: 5px;
    }

    .description-row {
      display: none;
    }
  }

  .page-break {
    page-break-after: always;
  }

  #journey-content-row {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  #journey-sidebar {
    display: none !important;
  }

  #footer {
    page-break-inside: avoid;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    padding-top: 25px;
    padding-bottom: 25px;

    .ui.grid + .grid {
      margin-top: 0px !important;
    }

    .sba-links-grid {
      display: none;
    }

    .sba-footer-divider-grid {
      display: none;
    }

    #socials {
      display: none;
    }
  }

  #topic-content-view {
    width: 100% !important;
    padding-bottom: 50px;

    .event-meta {
      display: none !important;
    }

    .learning-event-container {
      padding-top: 0 !important;
      box-shadow: none !important;
      border: none !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .media-transcript-container {
      margin-left: auto !important;
      margin-right: auto !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .event-footer-divider {
    display: none !important;
  }

  .learning-event-footer {
    display: none !important;
  }

  .infographic-template-body-field {
    display: none;
  }
}
