@import "../../assets/styles/base";
.login-error-message {
  margin-top: 25px;
  font-weight: bold;
}

.login-screen {
  .primary_text {
    @include themify($themes) {
      color: themed("login-form-primary-text");
    }
  }

  .remember_me {
    &:focus-within {
      @include themify($themes) {
        outline: solid 2px themed("outline-color");
      }
    }
    padding: 7px 0px;
  }

  #remember-me-label {
    margin-left: 4px;
    display: block;

    @include themify($themes) {
      color: themed("login-form-remember-label");
    }
  }

  .button-forgot-password {
    @include button-as-link;

    font-style: italic;
    margin-right: 0px;
    padding-top: 6px;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;

    @include themify($themes) {
      color: themed("login-form-forgot-password");
    }

    &:hover {
      text-decoration: underline;

      @include themify($themes) {
        background-color: themed(
          "login-form-forgot-password-hover-bg"
        ) !important;
      }
    }
    &:focus {
      outline: solid 2px $outline-color;

      @include themify($themes) {
        outline: solid 2px themed("outline-color");
        background-color: themed(
          "login-form-forgot-password-focus-bg"
        ) !important;
      }
    }
  }
}
