@import "../../../assets/styles/base";

.ui.button.event-footer-buttons {
  height: 36px;
  width: 150px;
  margin-top: 21px;
  margin-bottom: 21px;
  margin-right: 0;
  margin-left: 0;

  &:hover {
    text-decoration: underline;

    @include themify($themes) {
      color: themed("event-footer-buttons-hover");
    }
  }

  &.right {
    padding-left: 15px !important;
    padding-right: 41px !important;
    text-align: center;

    &:active {
      text-decoration: none;

      @include themify($themes) {
        background-color: themed("event-footer-right-active-bg");
        color: themed("event-footer-right-active-color");
      }
    }

    &:hover,
    &:focus {
      text-decoration: underline;

      @include themify($themes) {
        background-color: themed("event-footer-right-hover-bg");
        color: themed("event-footer-right-hover-color");
      }
    }
  }

  &.left {
    @include themify($themes) {
      background-color: themed("event-footer-left-bg");
    }

    &:active,
    &:focus {
      @include themify($themes) {
        background-color: themed("event-footer-left-active-bg");
        color: themed("event-footer-left-active-color");
      }
    }

    &:hover {
      text-decoration: underline;

      @include themify($themes) {
        background-color: themed("event-footer-left-hover-bg");
      }
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .ui.button.event-footer-buttons {
    width: 45%;

    &.left {
      padding-left: 3em !important;
    }
  }

  .event-footer-button-link:only-child > button {
    float: right;
  }
}
