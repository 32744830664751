@import "../../../assets/styles/base";

#video-player {
  margin: 0 0 0 0;

  .video-player-wrapper {
    padding-bottom: 0 !important;
  }

  .video-row {
    padding: 0 0 2px 0 !important;
  }

  &.fullscreen .video-player-controls {
    &.user-active {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .video-player-controls {
    width: 100% !important;
    margin: -3px 0 0 0 !important;

    @include themify($themes) {
      background-color: themed("video-player-controls-bg");
    }

    &.ui.grid {
      padding: 0 5px;
    }

    i.closed.captioning {
      color: $sba-gold !important;

      @include themify($themes) {
        color: themed("video-player-controls-captions-color") !important;
      }
    }

    .ui.basic.icon.button {
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      border: 1px solid transparent;

      @include themify($themes) {
        color: themed("video-player-controls-icon") !important;
      }

      & i {
        color: inherit;
      }

      &:hover {
        color: $sba-gold !important;

        @include themify($themes) {
          color: themed("video-player-controls-hover");
        }
      }

      &:hover,
      &:focus {
        background-color: transparent !important;
        text-decoration: none;

        @include themify($themes) {
          border: 1px solid themed("video-player-controls-border-hover");
        }
      }
    }

    .video-duration-wrapper {
      text-align: right;
    }

    .video-duration {
      margin-top: 8px;

      @include themify($themes) {
        color: themed("video-player-controls-duration");
      }
    }

    .volume-seek-bar-wrapper {
      display: flex;
      flex-direction: column;

      .volume-seek-bar {
        justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
        padding: 5px 0;
        width: 80px;
        border: 1px solid transparent;

        & .volume-slider-handle:hover,
        & .volume-slider-handle:focus {
          @include themify($themes) {
            border: 1px solid
              themed("video-player-controls-volume-slider-hover-border") !important;
          }
        }
      }
    }

    #video-controls-row {
      .video-buttons-column {
        padding-left: 0px;
        padding-right: 0px;
      }

      .video-buttons-left {
        float: left;
        display: flex;
      }

      .video-buttons-right {
        float: right;
      }
    }

    &.stacked-controls {
      .video-duration {
        margin-top: 5px;
      }

      #video-scrubber-row {
        padding-bottom: 10px !important;

        .video-seek-bar {
          margin-top: 5px;
        }
      }

      #video-controls-row {
        .video-buttons-column {
          margin-bottom: 5px;
        }
      }
    }

    &.flat-controls {
      display: flex;
      padding: 5px 0;

      .volume-seek-bar-wrapper {
        height: auto;

        .volume-seek-bar {
          width: 100px;
          padding-right: 10px;
          align-content: center;
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      .video-scrubber-wrapper {
        flex: 1 1 auto;
        .video-seek-bar {
          margin-top: 11px;
          padding: 0 18px;
        }
      }

      .video-duration-wrapper {
        margin: 0 0.25em 0 0;
        padding: 0 0.61em;
      }
    }
  }
}

.video-tooltip-time {
  font-size: 18px;
}

.rc-slider-tooltip-inner {
  padding: 10px 10px 10px 10px !important;
  box-shadow: none !important;
  height: 100% !important;
  border-radius: 0 !important;
}

.video-scrubber-tooltip:after,
.video-scrubber-tooltip:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.video-scrubber-tooltip:after {
  border-width: 4px;
  margin-left: -4px;

  @include themify($themes) {
    border-color: themed("video-player-controls-scrubber-tooltip-border");
    border-top-color: themed(
      "video-player-controls-scrubber-tooltip-border-top"
    );
  }
}
.video-scrubber-tooltip:before {
  border-width: 5px;
  margin-left: -5px;

  @include themify($themes) {
    border-color: themed("video-player-controls-scrubber-tooltip-border");
    border-top-color: themed(
      "video-player-controls-scrubber-tooltip-border-top"
    );
  }
}

@media only screen and (max-width: $mobile-width) {
  #video-player {
    .video-player-controls {
      &.stacked-controls {
        #video-scrubber-row {
          padding-left: 16px !important;
          padding-right: 16px !important;

          .video-seek-bar {
            margin: 0 0 0 0;
          }
        }

        .video-duration {
          font-size: 16px;
          float: right;
          padding-right: 14px;
        }
      }
    }
  }
}

/* ------ Internet Explorer 11 overrides ------- */

@media all and (-ms-high-contrast: none) {
  .video-scrubber-tooltip {
    left: -55%;
  }
}
