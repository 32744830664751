@import "../../assets/styles/base";

.checkbox-question {
  margin-bottom: 0;
}

.checkbox-instructions {
  margin-bottom: 10px;
}

.checkbox-choice {
  margin-bottom: 5px;
}

.checkbox-wrapper {
  padding-top: 5px;

  &.custom-checkbox {
    display: flex;

    .custom-text-input > input {
      transform: translateZ(0);
    }

    .checkbox-choice {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .ui.input {
      margin-left: 5px;
      flex-grow: 100;
    }
  }
}
