@import "../../assets/styles/base";
@import "../../assets/styles/themes";

$font-size-small: 30px;
$font-size-medium: 40px;
$font-size-large: 50px;
$font-size-x-large: 60px;

$computer-font-size-small: 25px;
$computer-font-size-medium: 30px;
$computer-font-size-large: 35px;
$computer-font-size-x-large: 40px;

$mobile-font-size-small: 25px;
$mobile-font-size-medium: 30px;
$mobile-font-size-large: 35px;
$mobile-font-size-x-large: 40px;

#hero-splash {
  @include themify($themes) {
    background-color: themed("homepage-hero-splash-background");
  }

  background-repeat: no-repeat, repeat-x;
  max-height: 650px;
  background-size: cover, cover;
  background-position: center 100%, center center;
  padding-bottom: 12%;
  padding-top: 40px;

  .ascent-label {
    font-size: 40px;

    @include themify($themes) {
      color: themed("homepage-hero-splash-label-color");
    }
  }
  .tag-line-row {
    padding-top: 10px;
    padding-bottom: 10px;

    .tag-line {
      @include themify($themes) {
        color: themed("homepage-hero-splash-tagline-color");
      }
      font-size: 20px;
      line-height: 1.2;
    }
  }

  .title-line-row {
    padding-top: 10px;
    padding-bottom: 10px;

    .title-line {
      @include themify($themes) {
        color: themed("homepage-hero-splash-tagline-color");
      }
      font-size: 28px;
      line-height: 1.2;
    }
  }

  .hero-image {
    max-height: 140px;
  }

  .ui.large.button.homepage-button {
    margin-bottom: 20px;
  }
}

#homepage {
  .ui.divider {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .font-size-small {
    font-size: $font-size-small;
  }

  .font-size-medium {
    font-size: $font-size-medium;
  }

  .font-size-large {
    font-size: $font-size-large;
  }

  .font-size-x-large {
    font-size: $font-size-x-large;
  }
}

.homepage-container {
  text-align: center;
}

.ui.large.button.homepage-button {
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  @include themify($themes) {
    background-color: themed("homepage-button-background");
    color: themed("homepage-button-color");
  }
}

.homepage-footer-teaser {
  @include themify($themes) {
    background-color: themed("homepage-footer-background");
  }
}

.disclaimer {
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #hero-splash {
    background-size: cover, contain;
    background-position: center 0, center 0;
    padding-top: 0%;
    padding-bottom: 18%;
  }
}
/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #hero-splash {
    background-size: cover, contain;
    background-position: center 0, center 0;
    padding-bottom: 14%;

    .row {
      padding-top: 6x;
      padding-bottom: 6px;
    }
  }
}
/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  #hero-splash {
    background-size: contain, contain;
    background-position: center 50%, center;
    padding-bottom: 14%;

    .row {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  #hero-splash {
    background-size: contain, contain;
    background-position: center 100%, center;
  }
}

@media only screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
  #sba-main-row > .column > .content-window > div.homepage {
    padding: 0px !important;
    padding: 0px !important;
  }

  #hero-splash {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    margin: -1rem 0;
  }
}

@media only screen and (max-width: $mobile-width) {
  #sba-main-row > .column > .content-window > div.homepage {
    padding: 0px !important;
    padding: 0px !important;
  }

  #hero-splash {
    margin-top: -3.6rem;
    margin-left: -2rem;
    margin-right: -2rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 50%;

    div {
      opacity: 1;
    }

    &::after {
      content: "";
      opacity: 0.4;
      top: 0;
      left: 0;
      height: 100vh;
      right: 0;
      position: fixed;
      z-index: -1;
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .homepage-container p {
    padding-left: 1em;
    padding-right: 1em;
  }
}
