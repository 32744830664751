@import "../../assets/styles/base";

$slider-background: #d3d3d3;

.slider {
  height: 10px;
  width: 500px;
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
}

.rc-slider-rail, .rc-slider-track {
  border-radius: 4px;
}

.rc-slider.volume-slider {
  width: 50px;
  cursor: pointer;
  display: inline-block;
}

@media only screen and (max-width: $mobile-width) {
  .slider {
    margin: 10px 0 10px 0;
    width: 100%;
  }

  .rc-slider-handle {
    transform: none !important;
  }
}

/* ------ Internet Explorer 11 overrides ------- */

@media all and (-ms-high-contrast: none) {
  .slider {
    height: auto !important;
    background: transparent;
  }

  .volume-slider {
    height: auto !important;
    background: transparent;
  }
}
