@import "../../assets/styles/base";

#registration-modal {
  .ui.message {
    padding: 0.8em 1.5em;
  }

  hr {
    margin-bottom: 33px;
  }
}

div.ui.container.signup-form {
  margin-top: 20px;

  h2 {
    text-align: left;
    margin-top: 1.5rem;
    font-size: 14px;
    font-weight: bold;

    @include themify($themes) {
      color: themed("signup-form-header");
    }
  }
  &.bg-gray {
    margin: 2rem -3rem -3rem -3rem;
    padding: 2rem 3rem;
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;

    @include themify($themes) {
      background-color: themed("signup-form-gray-bg");
    }
  }
  &.middle.aligned {
    padding: 1rem 0;
  }

  .omb-expiration {
    text-align: right;
  }

  .omb-control-information {
    margin-top: 20px;
  }
}

.login-link {
  &:hover {
    text-decoration: underline;
  }
}

#business-question {
  position: relative;
  margin-inline-start: none;
  margin-inline-end: none;
  padding-block-start: none;
  padding-inline-start: none;
  padding-inline-end: none;
  padding-block-end: none;
  min-inline-size: none;
  border: none;
  width: 100% !important;
  padding: 0;
  padding-top: none;
  padding-bottom: none;

  &:focus-within {
    @include themify($themes) {
      outline: solid 2px themed("outline-color");
    }
  }
}

#in-business-text {
  @include themify($themes) {
    color: themed("signup-form-in-business-text");
  }
}

#business-question-title {
  order: 1;
  display: flex;
  margin: 0 0 0.22222222rem 0;
  font-size: 0.94444444em;
  font-weight: 700;
  text-transform: none;
  align-items: center;
  float: left;
  width: 37.5% !important;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @include themify($themes) {
    color: themed("signup-form-business-question-title");
  }
}

.radioGroup {
  width: 18.75% !important;
  flex-direction: row;
  flex-wrap: nowrap;
  float: left;
  align-items: center;
}

@media only screen and (max-width: $tablet-width) {
  div.ui.container.signup-form {
    #account-text {
      padding-right: 0;
    }

    #sign-up-next-button {
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  div.ui.container.signup-form {
    .omb-expiration {
      text-align: left;
    }
  }
}
