@import "../../assets/styles/base";
$card-item-width: 320px;

#card-grid {
  margin-bottom: 100px;

  #card-row {
    margin-top: 25px;
  }
}

#card-list {
  margin-top: 25px;
  justify-content: space-between;
  flex-flow: row wrap;
  outline: none;

  &::after {
    content: "";
    width: 320px;
    margin-left: 9px;
    margin-right: 9px;
  }

  h3 {
    font-weight: bold;
    width: 100%;
    display: block;
  }
}

.card-item.ui.card {
  width: $card-item-width;

  .meta {
    margin-top: 3px;
    font-style: italic;

    @include themify($themes) {
      color: themed("font-color");
    }
  }

  .card-progress-content {
    border-top: none !important;
  }

  .ui.placeholder {
    animation: placeholderShimmer 4s linear;
    animation-iteration-count: infinite;
  }
}

.card-item-header {
  @include themify($themes) {
    color: themed("card-item-header");
  }
}

.card-progress-bar-row {
  padding-bottom: 0 !important;
}

.card-completed-row {
  padding-top: 0 !important;

  @include themify($themes) {
    color: themed("card-completed-row-color");
  }
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
  #card-container {
    width: 770px;
    padding-left: 1em;
  }
}

@media only screen and (max-width: $mobile-width) {
  #card-grid {
    margin-left: 0;
    margin-right: 0;
    margin-top: -3rem;
  }

  #journeys {
    padding-left: 0;
    padding-right: 0;
  }

  #card-header {
    width: 100%;
    padding-bottom: 0 !important;
    & > .ui.header {
      margin-bottom: 0;
    }

    & > .ui.diviver {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }

  .card-progress.ui.grid {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  #card-list {
    padding-left: 0px;
    padding-right: 0px;
    justify-content: space-between;
    margin-top: 0;

    .card-item.ui.card {
      width: 100%;
      margin-top: 0.5em;
      margin-bottom: 0.5em;

      &:first-child {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 50px;
      }
    }
  }
}
