@import "../../assets/styles/base";

$navbar-height: 100px;
$mobile-navbar-height: 75px;

#sba-main-row {
  padding: 0px;
}

.ui.menu .item {
  padding-left: 0px;
}

.navigation-journey-menu-header {
  text-transform: none !important;
  font-size: 29px !important;
  a {
    @include themify($themes) {
      color: themed("navbar-menu-header-a") !important;
    }
  }
}

.ui.image.logo.logo-small {
  display: none;
}

.navigation-journey-intro {
  padding-top: 40px !important;
  padding-left: 25px !important;
  padding-bottom: 20px;
  white-space: normal;
  font-weight: normal;
  border-bottom: $main-border;
  margin-bottom: 1rem;
  display: flex;
  max-width: 100%;

  p {
    display: block;
    padding-top: 10px;
  }

  .navigation-journey-menu-image {
    padding-right: 0 !important;
    flex: 0 0 120px;

    .navigation-journey-image {
      width: 100px;
      height: 100px;
    }
  }

  .navigation-journey-menu-head {
    flex: 1 0 0;
  }
}

.navigation-topics-list {
  margin: -10px 25px 15px 35px !important;
  width: 100%;
}

h4.ui.header.navigation-browse-journey-header {
  margin-top: 10px;

  @include themify($themes) {
    color: themed("navbar-dropdown-header");
  }
}

.navbar.ui.menu {
  height: $navbar-height;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 1rem;
  padding-bottom: 1px;
  border-bottom: 2px solid #sba-grey-one;

  @include themify($themes) {
    background-color: themed("navbar-ui-menu-bg");
  }

  a {
    padding-bottom: 0;
  }

  .dropdown.navigation-journey {
    padding-bottom: 0;
    margin-right: 20px;
    font-weight: bold;

    .navigation-journey-dropdown-menu {
      position: fixed !important;
      min-height: 400px;
      height: 100% !important;
      margin-left: 1px !important;
      margin-right: 10px !important;
      border-radius: 0 !important;
      min-width: 980px;

      @include themify($themes) {
        background-color: themed("navbar-dropdown-menu-bg") !important;
      }

      .navigation-journey-item-menu {
        height: 100%;
        width: 100%;
        margin-top: 0;
        overflow-y: auto;

        .navigation-topics-section {
          position: relative;
        }
      }
    }
  }

  .text {
    @include themify($themes) {
      color: themed("navbar-menu-text");
    }
  }

  .icon {
    @include themify($themes) {
      color: themed("navbar-menu-text");
    }
  }

  .icon.circle {
    @include themify($themes) {
      color: themed("navbar-helpdesk-link-color");
    }
  }

  #navbar-journeys {
    padding-top: 24px;
    margin-right: 15px;

    @include themify($themes) {
      //background-color: themed("navbar-journeys-bg");
    }

    &.underline-text > .text {
      text-decoration: underline;
    }

    .icon {
      margin-left: 5px !important;
    }

    &.active {
      @include themify($themes) {
        background-color: themed("navbar-journeys-active-bg");
        color: themed("navbar-journeys-active-color");
      }

      .icon {
        @include themify($themes) {
          color: themed("navbar-journeys-active-color");
        }
      }

      .menu {
        @include themify($themes) {
          background-color: themed("navbar-journeys-active-menu-bg");
        }
      }

      .text {
        @include themify($themes) {
          color: themed("navbar-journeys-active-color");
        }
      }

      a {
        @include themify($themes) {
          color: themed("navbar-journeys-active-a-color");
        }
      }

      .journey-item {
        padding: 0px !important;

        .icon {
          @include themify($themes) {
            color: themed("navbar-journeys-active-item");
          }
        }

        .text {
          margin-right: 30px;

          @include themify($themes) {
            color: themed("navbar-journeys-active-item");
          }
        }

        i {
          float: right;
          margin: 0 !important;
        }

        .navigation-journey {
          padding: 20px !important;

          &.dropdown.visible {
            .menu {
              @include themify($themes) {
                background-color: themed(
                  "navbar-journeys-active-item-visible-bg"
                );
              }
            }

            img {
              margin-right: 20px;
            }

            .topic-summary {
              margin-left: 15px;

              .path-description {
                white-space: normal;
                margin-top: 15px;
              }
            }
          }
        }

        &:hover,
        &.selected {
          @include themify($themes) {
            background-color: themed(
              "navbar-journeys-active-hover-bg"
            ) !important;
          }

          .text {
            text-decoration: underline;

            @include themify($themes) {
              color: #fff;
            }
          }
          .icon {
            @include themify($themes) {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .search-bar {
    padding: 0;
    padding-top: 13px;

    .fields {
      margin-bottom: 0;
    }

    .button {
      padding-left: 21px;
      padding-right: 21px;

      @include themify($themes) {
        color: themed("navbar-search-button-color");
        background-color: themed("navbar-search-button-bg");
      }
    }
  }

  .registration-buttons {
    padding-bottom: 0;
    padding-right: 0;

    
    .button {
      //background-color: $sba-navy;
      //color: $sba-white;
      //border-radius: 0 !important;
      margin-right: 1em !important;

      @include themify($themes) {
        color: themed("navbar-register-button-color");
        background-color: themed("navbar-register-button-bg");
      }

      &:hover,
      &:focus {
        background-color: $sba-white;
        color: $sba-navy;

        @include themify($themes) {
          color: themed("navbar-register-button-hover-color");
          background-color: themed("navbar-register-button-hover-bg");
        }
      }
    }
  }

  #helpdesk-link {
    padding: 12px 17px 0px 17px;
    margin-top: 15px;
    //background-color: $sba-navy;
    //color: $sba-blue-two;

    @include themify($themes) {
      color: themed("navbar-helpdesk-link-color");
      background-color: themed("navbar-helpdesk-link-bg");
    }

    @media only screen and (max-width: $mobile-width) {
      padding-left: 17px;
      padding-bottom: 10px;
      margin-top: 0;
    }

    i {
      margin: 0px;
      text-decoration: none;
    }
  }

  .user-buttons {
    width: 150px;
    //color: $sba-white;
    padding-bottom: 0;

    @include themify($themes) {
      color: themed("navbar-user-button");
    }

    a {
      //color: $sba-white;

      @include themify($themes) {
        color: themed("navbar-user-button");
      }
    }

    .greeting {
      padding: 11px;
    }

    .logout-link-button {
      @include button-as-link;
      color: $sba-navy;
      background-color: $sba-white;
      height: 100%;
      border-radius: 0 !important;
      margin-left: auto !important;
      margin-right: auto !important;

      @include themify($themes) {
        color: themed("navbar-logout-link-color");
        background-color: themed("navbar-logout-link-bg");
      }
    }
  }
}

.navbar {
  // NavigationLearningObjective.js
  .navbar-topic-item {
    padding: 0px !important;
    outline: none;
    &.active {
      & .navbar-topic-item-link {
        text-decoration: underline;
        //background-color: $sba-light-grey !important;

        @include themify($themes) {
          background-color: themed("navbar-active-topic-item-bg") !important;
        }
      }
    }

    .navbar-topic-item-link {
      padding: 13px 16px !important;
      display: inline-block;
      width: 100%;
      border: $main-border;

      &:hover {
        //background-color: $sba-light-grey !important;
        text-decoration: underline;
        cursor: pointer;

        @include themify($themes) {
          background-color: themed(
            "navbar-active-topic-item-link-hover-bg"
          ) !important;
        }
      }

      span > em {
        float: right;
      }
    }
  }
}

[id^="skip-link"] {
  position: absolute;
  left: -999em;

  &:active,
  &:focus {
    left: 1em;
    top: 52px;
    //background-color: $sba-navy;
    //color: $sba-white;
    margin-left: 15px;
    margin-top: 15px;
    border: none;
    outline: none;
    padding: 5px;
    z-index: 999;

    @include themify($themes) {
      color: themed("skiplink-active-color");
      background-color: themed("skiplink-active-bg");
    }
  }
}

.header-menu {
  display: block;
  margin-bottom: 0.5em;
}

.header-menu .ui.menu .item:before {
  width: 0;
}

#content {
  outline: none;
}

@media only screen and (max-width: $tablet-width) {
  #navigation_site_search {
    width: 255px;
  }
}

@media only screen and (max-width: $tablet-width) {

}



@media only screen and (max-width: $mobile-width) {
  .header-menu {
    margin-bottom: 1.5em;
    position: fixed;
    z-index: 99;
    width: 100%;
  }

  #content {
    margin-top: $mobile-navbar-height;
  }

  #navigation_site_search {
    margin-top: $mobile-navbar-height;
    padding: 0.5em 1em;
    //background-color: $sba-navy;
    box-shadow: $main-tail-shadow;
    width: 100%;

    @include themify($themes) {
      background-color: themed("navbar-navigation-stie-search-bg");
    }

    button {
      padding-left: 20px;
    }
  }

  .navbar.ui.menu {
    margin: 0px;
    height: $mobile-navbar-height;

    .header {
      padding: 0px;
    }

    i.icon {
      margin-left: 1em;
      font-size: 1.7em;
    }

    #navbar-journeys {
      margin-right: 5px;
      padding: 12px 5px;

      .navigation-journey-menu {
        width: 100vw;
        height: calc(100vh - #{$mobile-navbar-height});
        padding-bottom: 150px;
        bottom: 0px;
        top: $mobile-navbar-height;
        position: fixed;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }

      .journey-item .navigation-journey {
        .text {
          margin-right: 0;
        }

        i {
          padding-top: 20px;
          float: right;
          margin: 0 10.5px 0 5px !important;
        }
      }
    }

    #navbar-journeys.active .journey-item .navigation-journey {
      padding: 0 !important;

      .text {
        padding: 20px 20px 20px 10px;
      }
    }

    a {
      padding-bottom: 0;
    }

    .item {
      padding-top: 0em;
    }

    .dropdown.navigation-journey {
      padding-bottom: 0;
      margin-right: 20px;
      font-weight: bold;

      .navigation-journey-dropdown-menu {
        position: relative !important;
        min-height: $mobile-navbar-height;
        margin-left: 0px !important;
        margin-right: 0px !important;
        border-radius: 0 !important;
        min-width: 100px !important;
        left: 0px;

        @include themify($themes) {
          background-color: themed(
            "navbar-dropdown-navigation-menu-bg"
          ) !important;
        }

        .navigation-journey-item-menu {
          height: 100%;
          margin-top: 0;
          overflow-y: auto;

          .navigation-topics-section {
            position: relative;
          }
        }
      }
    }

    .navbar-topic-item {
      padding: 0 0 0 0 !important;
      margin-left: -5px;
      border-bottom: $main-border;

      .navbar-topic-item-link {
        border: 0;

        .navbar-topic-item-summary {
          display: none;
        }
      }
    }

    .navigation-topics-list {
      margin: 0 !important;
      width: 100%;
    }

    .registration-buttons {
      padding: 0;

      .login-button {
        height: auto;
        border-radius: 0.222rem !important;

        @include themify($themes) {
          background-color: themed("navbar-register-login-button-bg");
        }
      }
    }
  }
}

#navbar-user-dropdown,
#navbar-help-dropdown {
  padding-top: 27px;
  padding-right: 15px;

  &.focused {
    & > .text {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: $tablet-width) {
    padding-top: 27px;
  }

  @media only screen and (max-width: $mobile-width) {
    padding-top: 15px;
  }

  &.active {
    @include themify($themes) {
      background-color: themed("navbar-simple-dropdown-active-bg");
    }
  }

  .menu {
    @include themify($themes) {
      background-color: themed("navbar-simple-dropdown-bg");
    }
  }

  .icon {
    font-size: 1.5em;
  }

  .simple-dropdown-item {
    outline: none;
    &.active,
    &.selected {
      @include themify($themes) {
        background-color: themed(
          "navbar-simple-dropdown-active-item-bg"
        ) !important;
      }
      .text {
        @include themify($themes) {
          color: themed("navbar-simple-dropdown-active-item-color");
        }
      }
    }

    &:hover {
      @include themify($themes) {
        background-color: themed(
          "navbar-simple-dropdown-active-item-bg"
        ) !important;
      }

      .text {
        text-decoration: underline;

        @include themify($themes) {
          color: themed("navbar-simple-dropdown-active-item-color");
        }
      }
    }

    .text {
      font-weight: bold;

      @include themify($themes) {
        color: themed("navbar-simple-dropdown-item-color");
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .navbar.ui.menu {
    .dropdown.navigation-journey {
      .navigation-journey-dropdown-menu {
        min-width: calc(100vw - 550px) !important;
      }
    }
  }
  
}

@media only screen and (max-width: 1300px) {
  .ui.image.logo.logo-small {
    display: inline;
  }
  .ui.image.logo.logo-large {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .navbar.ui.menu {
    .dropdown.navigation-journey {
      .navigation-journey-dropdown-menu {
        min-width: calc(100vw - 450px) !important;
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .dropdown.navigation-journey {
    .navigation-journey-dropdown-menu {
      .navigation-journey-menu-image {
        display: none;
      }
    }
  }
}

/* ------ Internet Explorer 11 overrides ------- */
@media all and (-ms-high-contrast: none) {
  .navbar.ui.menu {
    .dropdown.navigation-journey {
      .navigation-journey-dropdown-menu {
        // Currently we use position relative for other browsers. Causes IE to show
        // Modal off the screen. This shows modal but not in same manner as other browsers
        // Makes it functional
        position: absolute !important;
      }
    }
  }
}

/* ------ Microsoft Edge overrides ------- */
@supports (-ms-ime-align: auto) {
  .navbar.ui.menu {
    .dropdown.navigation-journey {
      .navigation-journey-dropdown-menu {
        // Currently we use position relative for other browsers. Causes IE to show
        // Modal off the screen. This shows modal but not in same manner as other browsers
        // Makes it functional
        position: absolute !important;
      }
    }
  }
}

/* ------ Safari overrides ------- */
@media only screen and (min-width: $mobile-width) {
  .navbar.ui.menu {
    .dropdown.navigation-journey {
      .navigation-journey-dropdown-menu.override-position {
        // Currently we use position relative for other browsers. Causes IE to show
        // Modal off the screen. This shows modal but not in same manner as other browsers
        // Makes it functional
        position: absolute !important;
      }
    }
  }
}
