@use "sass:math";
@import "../../../../assets/styles/base";

// ** Styled Un-ordered lists **

$bullet-colors: (
  "orange": $sba-orange,
  "dark-orange": $sba-dark-orange,
  "black": $sba-black,
  "navy": $sba-navy,
  "light-blue": $sba-light-blue,
  "dark-teal": $sba-dark-teal,
  "teal": $sba-teal,
  "blue": $sba-blue,
  "gold": $sba-gold,
  "gray": $sba-grey-three,
  "white": $sba-white,
  "red": $sba-red,
);

// Size label, Size value, margin top
$triangle-bullet-sizes: (
  "small" 14px 7px,
  "medium" 20px 4px,
  "large" 30px -2px
);

@mixin triangle-bullets($size, $color, $margin) {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: $size * 1.5;
  margin-top: 0;
  margin-bottom: 0;

  & > li {
    padding-top: 10px;
  }
  & > li:before {
    width: 0;
    height: 0;
    border-top: math.div($size, 2) solid transparent;
    border-left: $size solid $color;
    border-bottom: math.div($size, 2) solid transparent;
    margin-top: $margin;

    content: "";
    padding-right: 5px;
    position: absolute;
    left: 0;
  }
}

//Size label, size value, margin-top
$circle-bullet-sizes: ("small" 14px 10px, "medium" 20px 8px, "large" 30px 6px);

@mixin circle-bullets($size, $color, $margin) {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: $size;
  margin-top: 0;
  margin-bottom: 0;

  & > li {
    padding-top: 10px;
  }
  & > li:before {
    width: math.div($size, 2);
    height: math.div($size, 2);
    margin-top: $margin;
    border-radius: 50%;
    background: $color;

    content: "";
    padding-right: 5px;
    position: absolute;
    left: 0;
  }
}

//Size label, size value, margin-top
$square-bullet-sizes: ("small" 14px 6px, "medium" 18px 4px, "large" 25px 2px);

@mixin square-bullets($size, $color, $margin) {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: $size * 1.5;
  margin-top: 0;
  margin-bottom: 0;

  & > li {
    padding-top: 10px;
    margin-left: 10px;
  }
  & > li:before {
    width: $size;
    height: $size;
    margin-top: $margin;
    border: math.div($size, 5) solid $color;

    content: "";
    padding-right: 5px;
    position: absolute;
    left: 0;
  }
}

.list {
  &.triangle-bullet {
    @each $size, $value, $margin in $triangle-bullet-sizes {
      &.bullet-size-#{$size} {
        @each $name, $bullet-color in $bullet-colors {
          &.bullet-color-#{$name} {
            & > ul {
              @include triangle-bullets($value, $bullet-color, $margin);
            }
          }
        }
      }
    }
  }

  &.circle-bullet {
    @each $size, $value, $margin in $circle-bullet-sizes {
      &.bullet-size-#{$size} {
        @each $name, $bullet-color in $bullet-colors {
          &.bullet-color-#{$name} {
            & > ul {
              @include circle-bullets($value, $bullet-color, $margin);
            }
          }
        }
      }
    }
  }

  &.square-bullet {
    @each $size, $value, $margin in $square-bullet-sizes {
      &.bullet-size-#{$size} {
        @each $name, $bullet-color in $bullet-colors {
          &.bullet-color-#{$name} {
            & > ul {
              @include square-bullets($value, $bullet-color, $margin);
            }
          }
        }
      }
    }
  }
}

// ** Styled Ordered Lists **

//Name, Background-Color, Font-Color
$number-colors: (
  "orange" $sba-orange $sba-navy,
  "navy" $sba-navy $sba-white,
  "light-blue" $sba-light-blue $sba-grey-three,
  "dark-teal" $sba-dark-teal $sba-white,
  "teal" $sba-teal $sba-white,
  "blue" $sba-blue $sba-white,
  "gold" $sba-gold $sba-navy,
  "gray" $sba-grey-three $sba-white,
  "black" $sba-white $sba-black,
  "white" $sba-white $sba-navy,
  "red" $sba-red $sba-white,
  "dark-orange" $sba-dark-orange $sba-white
);

//Size Label, Size Value, margin-top
$numbered-list-sizes: ("small" 35px 5px, "medium" 40px 4px, "large" 50px -4px);

@mixin ol-style {
  counter-reset: bullet-counter;

  position: relative;
  list-style: none;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  & > li {
    counter-increment: bullet-counter;
    padding-top: 10px;
    margin-left: 10px;
  }

  & > li:before {
    content: counter(bullet-counter);
    position: absolute;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
  }
}

@mixin numbered-list($size, $margin, $font-color) {
  padding-left: $size * 1.25;

  & > li:before {
    width: $size;
    height: $size;
    margin-top: $margin;

    color: $font-color;
    font-size: $size * 0.6;
  }
}

@mixin numbered-list-circled($size, $background-color, $margin, $font-color) {
  padding-left: $size * 1.25;

  & > li:before {
    width: $size;
    height: $size;
    margin-top: $margin;

    border-radius: 50%;
    background: $background-color;

    color: $font-color;

    font-size: $size * 0.6;
  }
}

.list {
  &.numbered-list-circled {
    @each $size, $value, $margin in $numbered-list-sizes {
      &.bullet-size-#{$size} {
        @each $name, $background-color, $font-color in $number-colors {
          &.bullet-color-#{$name} {
            ol {
              @include ol-style;
              @include numbered-list-circled(
                $value,
                $background-color,
                $margin,
                $font-color
              );
            }
          }
        }
      }
    }
  }

  &.numbered-list-uncircled {
    @each $size, $value, $margin in $numbered-list-sizes {
      &.bullet-size-#{$size} {
        @each $name, $font-color in $number-colors {
          &.bullet-color-#{$name} {
            ol {
              @include ol-style;
              @include numbered-list($value, $margin, $font-color);
            }
          }
        }
      }
    }
  }
}
