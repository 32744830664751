@import "../../assets/styles/base";

#home-excursion-grid {
  justify-content: space-between;
  &::after {
    width: 280px;
  }

  .description {
    font-size: $body-font-size;
    text-align: center;
    line-height: normal;
  }

  .header {
    text-align: center;
  }

  @media only screen and (max-width: 1310px) {
    justify-content: center;
  }

  .homepage-excursion-card {
    margin-left: 15px;
    margin-right: 15px;
    padding: 40px 0 30px 0;

    @media only screen and (max-width: $mobile-width) {
      padding: 20px 0 0 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    box-shadow: none;
    width: 250px;

    img {
      background: none;
    }

    .homepage-excursion-card-content {
      border-top: none;
      padding: 15px 30px 0px 30px;

      .homepage-excursion-card-header {
        margin: 0 0 0 0;
      }

      .homepage-excursion-card-description {
        font-size: 18px;
      }

      .header,
      .description {
        @include themify($themes) {
          color: themed("homepage-card-color") !important;
        }
      }
    }
  }
}
