@import "colors.scss";
@import "themes.scss";

$main-font: "Source Sans Pro", sans-serif;
$body-font-size: 16px;

$main-border: 1px solid $border-color;
$main-box-shadow: 0 1px 2px 0 $box-shadow-color;
$main-tail-shadow: 0 6px 4px -4px $box-shadow-color;
$box-shadow-two: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
$tablet-width: 991px;
$mobile-width: 767px;
$small-mobile-width: 320px;

@mixin button-as-link {
  border: none;
  font: inherit;
  font-weight: bold;
  color: $sba-blue;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
