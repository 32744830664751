// https://medium.com/@dmitriy.borodiy/easy-color-theming-with-scss-bc38fd5734d1
@import "colors.scss";

$themes: (
  ascent: (
    body-background-color: #fafbfd,
    body-color: $sba-grey-three,
    global-a-color: $sba-blue,
    global-hr-border: $sba-grey-one,
    global-button-primary-bg: $sba-blue,
    global-button-secondary-color: $sba-navy,
    global-button-secondary-bg: $sba-gold,
    global-active-color: $sba-grey-three,
    global-active-bg: $sba-grey-two,
    global-placeholder-color: $sba-grey-three,
    modals-header: $sba-navy,
    modals-close-button-focus-border: $sba-blue,
    modals-success-message: $sba-blue,
    semantic-hr-color: $semantic-hr-color,
    main-border: 1px solid $border-color,
    main-box-shadow: 0 1px 2px 0 $box-shadow-color,
    outline-color: $outline-color,
    box-shadow-color: $box-shadow-color,
    box-shadow-two-color: $box-shadow-two-color,
    border-color: $border-color,
    font-color: $font-color,
    font-color-darker: $font-color-darker,
    homepage-button-background: $sba-navy,
    homepage-button-color: white,
    homepage-hero-splash-background: $sba-white,
    homepage-footer-background: $sba-background-grey,
    homepage-hero-splash-label-color: $sba-blue,
    homepage-hero-splash-tagline-color: $sba-navy,
    homepage-card-color: $sba-dark-grey,
    excursion-content-view-background: $sba-white,
    excursion-content-grid-hover: $sba-white-two,
    excursion-content-grid-header-color: $sba-blue,
    excursion-content-grid-header-hover: $sba-orange,
    breadcrumb-header-grid-background-color: #ffffff,
    breadcrumb-header-content-color: $sba-blue,
    global-breadcrumb-section-color: $sba-navy,
    card-item-header: $sba-dark-grey,
    card-completed-row-color: $sba-dark-grey,
    checkmark-completed-color: #34bdc5,
    fireside-chat-disclaimer-color: $sba-dark-grey,
    excursion-content-type-color: $sba-grey-three,
    external-site-modal-header-color: $sba-navy,
    external-site-modal-divider: $sba-blue,
    footer-link-color: $sba-blue-two,
    footer-link-active-color: $sba-grey-two,
    footer-header-color: $sba-navy,
    footer-logo-color: $sba-navy,
    reset-modal-background: $sba-light-grey,
    intro-background-color: $sba-background-grey,
    excursion-section-background-color: $sba-white,
    journey-resume-background-color: $sba-white,
    journey-item-header-color: $sba-dark-grey,
    journey-completed-row-color: $sba-dark-grey,
    journeys-background-color: $sba-background-grey,
    dashboard-progress-background-color: white,
    event-footer-buttons-hover: $sba-grey-three,
    event-footer-right-active-bg: $sba-blue,
    event-footer-right-active-color: $sba-white,
    event-footer-right-hover-bg: $sba-grey-two,
    event-footer-right-hover-color: $sba-grey-three,
    event-footer-left-bg: $sba-grey-one,
    event-footer-left-active-bg: $sba-grey-two,
    event-footer-left-active-color: $sba-grey-three,
    event-footer-left-hover-bg: $sba-grey-two,
    video-player-controls-bg: $sba-dark-grey,
    video-player-controls-captions-color: $sba-gold,
    video-player-controls-icon: $sba-white,
    video-player-controls-hover: $sba-gold,
    video-player-controls-border-hover: grey,
    video-player-controls-duration: $sba-white,
    video-player-controls-volume-slider-hover-border: grey,
    video-player-controls-scrubber-tooltip-border: rgba(108, 108, 108, 0),
    video-player-controls-scrubber-tooltip-border-top: #6c6c6c,
    slider-background: $slider-background,
    slider-lower: $sba-blue,
    slider-upper: $slider-background,
    slider-progress: $sba-gold,
    slider-thumb-bg: $sba-white,
    event-container-bg: $sba-white,
    event-header: $sba-dark-grey,
    event-header-button-color: $sba-navy,
    event-header-button-bg: white,
    event-header-button-focus-bg: $sba-grey-one,
    event-header-button-focus-border: $sba-grey-one,
    event-podcast-container-button-focus: #96dbfa,
    event-tip-box-bg: $sba-white,
    event-tip-box-border: $sba-blue,
    event-tip-box-title-color: $sba-blue,
    event-button-gold-bg: $sba-gold,
    event-h3-color: rgb(11, 47, 107),
    event-h3-company-subheader-color: $sba-grey-three,
    event-header-references-color: $sba-grey-three,
    event-path-content-table-color: $sba-white,
    event-path-content-table-bg: $sba-blue,
    event-path-content-table-tr-even: $sba-background-grey,
    transcript-container-bg: $sba-white,
    transcript-download-button: $sba-navy,
    transcript-download-bg: white,
    transcript-download-hover: $sba-grey-one,
    transcript-content-wrapper-a: $sba-blue,
    transcript-menu-border-bottom: $sba-white,
    transcript-menu-item: $sba-grey-three,
    transcript-menu-item-border: $sba-white,
    transcript-menu-item-active-border: $sba-blue,
    transcript-menu-item-hover-border: $sba-grey-one,
    left-navigation-bg: $sba-white,
    active-nav-number-circle: $sba-navy,
    active-nav-number-circle-bg: $sba-gold,
    nav-number-circle-color: $sba-white,
    nav-number-circle-bg: $sba-navy,
    nav-number-circle-completed-color: $sba-white,
    nav-number-circle-completed-bg: $sba-navy,
    ln-excursion-title: $sba-navy,
    ln-excursion-content-bg: $sba-white-two,
    excursion-name-progress: $sba-grey-three,
    topic-accordion-placeholder-bg: white,
    event-list-active-event: $sba-dark-grey,
    event-list-item: $sba-navy,
    event-list-key-takeaway-bg: white,
    event-list-item-extra: $sba-grey-three,
    event-list-item-active-bg: $sba-grey-one,
    event-list-item-icon: $sba-navy,
    event-list-completed-icon: $sba-blue,
    login-form-primary-text: $sba-blue,
    login-form-remember-label: $sba-grey-three,
    login-form-forgot-password: black,
    login-form-forgot-password-hover-bg: white,
    login-form-forgot-password-focus-bg: transparent,
    numbered-circle-color: $sba-white,
    numbered-circle-bg: $sba-blue,
    numeric-question-label: white,
    password-validation: $sba-grey-three,
    password-validation-validated: $sba-blue,
    navbar-menu-header-a: $sba-blue,
    navbar-dropdown-header: $sba-white,
    navbar-dropdown-menu-bg: $sba-white,
    navbar-ui-menu-bg: $sba-white,
    navbar-menu-text: $sba-navy,
    navbar-journeys-bg: $sba-white,
    navbar-journeys-active-bg: $sba-light-grey,
    navbar-journeys-active-color: $sba-blue,
    navbar-journeys-active-hover-bg: $sba-blue,
    navbar-journeys-active-a-color: $sba-dark-grey,
    navbar-journeys-active-menu-bg: $sba-white-two,
    navbar-journeys-active-item: $sba-dark-grey,
    navbar-journeys-active-item-visible-bg: $sba-white,
    navbar-search-button-color: $sba-white,
    navbar-search-button-bg: $sba-secondary-blue,
    navbar-register-button-bg: $sba-secondary-blue,
    navbar-register-login-button-bg: $sba-secondary-blue,
    navbar-register-button-color: $sba-white,
    navbar-register-button-hover-bg: $sba-white,
    navbar-register-button-hover-color: $sba-navy,
    navbar-helpdesk-link-bg: $sba-light-grey,
    navbar-helpdesk-link-color: $sba-secondary-blue,
    navbar-user-button: $sba-white,
    navbar-logout-link-color: $sba-navy,
    navbar-logout-link-bg: $sba-white,
    navbar-active-topic-item-bg: $sba-light-grey,
    navbar-active-topic-item-link-hover-bg: $sba-light-grey,
    skiplink-active-bg: $sba-navy,
    skiplink-active-color: $sba-white,
    navbar-navigation-site-search-bg: $sba-navy,
    navbar-dropdown-navigation-menu-bg: $sba-white,
    navbar-simple-dropdown-bg: $sba-light-grey,
    navbar-simple-dropdown-active-bg: $sba-light-grey,
    navbar-simple-dropdown-active-item-bg: $sba-blue,
    navbar-simple-dropdown-active-item-color: $sba-white,
    navbar-simple-dropdown-item-color: $sba-dark-grey,
    path-progress-color: $sba-navy,
    path-progress-bg: $sba-white,
    path-progress-icon: $sba-gold,
    path-progress-meta: $sba-grey-three,
    profile-bg: white,
    profile-box-shadow: 0 1px 2px -2px #b2b9c4,
    profile-header: $sba-navy,
    profile-resend-verification: $sba-blue,
    profile-modal-note: $sba-blue,
    profile-modal-emphasis: black,
    optional-form-color: $sba-navy,
    optional-form-bg: $sba-grey-one,
    registration-complete-success: $sba-blue,
    reset-password-bg: $sba-light-grey,
    search-button-applied-filter-bg: white,
    search-button-add-filters: $sba-white,
    search-button-add-filters-active-bg: $sba-blue,
    search-border-color: $sba-grey-one,
    search-item-bg: $sba-white,
    search-pagination: $sba-blue-three,
    search-pagination-a-active: $sba-blue,
    search-header: $sba-navy,
    search-facet-checkbox: $sba-blue,
    share-modal-button-color: $sba-navy,
    share-modal-button-bg: white,
    share-modal-button-border: $sba-navy,
    share-modal-button-hover-bg: $sba-grey-one,
    share-modal-button-hover-border: $sba-grey-one,
    share-modal-p-color: $sba-navy,
    share-modal-share-copy-button: $sba-white,
    share-modal-share-copy-button-bg: $sba-blue,
    share-modal-facebook: $sba-facebook-blue,
    share-modal-twitter: $sba-twitter-blue,
    share-modal-linkedin: $sba-linkedin-blue,
    signup-form-header: black,
    signup-form-gray-bg: $sba-light-grey,
    signup-form-in-business-text: $sba-blue,
    signup-form-business-question-title: $font-color-darker,
    timeout-warning: $sba-navy,
    timeout-warning-logout-button-bg: $sba-grey-one,
    timeout-warning-logout-button-focus-bg: $sba-grey-two,
    touring-modal-circle-active: $sba-blue,
    touring-modal-header: $sba-blue,
    touring-modal-content-right-active-color: $sba-white,
    touring-modal-content-right-active-bg: $sba-blue,
    touring-modal-content-right-hover-color: $sba-grey-three,
    touring-modal-content-right-hover-bg: $sba-grey-two,
    touring-modal-carousel-circle: $sba-grey-one,
    touring-modal-carousel-circle-active: $sba-blue,
    touring-modal-hover-disabled-button-color: $sba-white,
    touring-modal-hover-disabled-button-bg: $sba-blue,
  ),
  learning-center: (
    body-background-color: #fafbfd,
    body-color: $sba-grey-three,
    global-a-color: $sba-blue,
    global-hr-border: $sba-grey-one,
    global-button-primary-bg: $sba-blue,
    global-button-secondary-color: $sba-navy,
    global-button-secondary-bg: $sba-gold,
    global-active-color: $sba-grey-three,
    global-active-bg: $sba-grey-two,
    global-placeholder-color: $sba-grey-three,
    modals-header: $sba-navy,
    modals-close-button-focus-border: $sba-blue,
    modals-success-message: $sba-blue,
    semantic-hr-color: $semantic-hr-color,
    main-border: 1px solid $border-color,
    main-box-shadow: 0 1px 2px 0 $box-shadow-color,
    outline-color: $outline-color,
    box-shadow-color: $box-shadow-color,
    box-shadow-two-color: $box-shadow-two-color,
    border-color: $border-color,
    font-color: $font-color,
    font-color-darker: $font-color-darker,
    homepage-button-background: $sba-navy,
    homepage-button-color: white,
    homepage-hero-splash-background: $sba-white,
    homepage-footer-background: $sba-background-grey,
    homepage-hero-splash-label-color: $sba-blue,
    homepage-hero-splash-tagline-color: $sba-navy,
    homepage-card-color: $sba-dark-grey,
    excursion-content-view-background: $sba-white,
    excursion-content-grid-hover: $sba-white-two,
    excursion-content-grid-header-color: $sba-blue,
    excursion-content-grid-header-hover: $sba-orange,
    breadcrumb-header-grid-background-color: #ffffff,
    breadcrumb-header-content-color: $sba-blue,
    global-breadcrumb-section-color: $sba-navy,
    card-item-header: $sba-dark-grey,
    card-completed-row-color: $sba-dark-grey,
    checkmark-completed-color: #34bdc5,
    fireside-chat-disclaimer-color: $sba-dark-grey,
    excursion-content-type-color: $sba-grey-three,
    external-site-modal-header-color: $sba-navy,
    external-site-modal-divider: $sba-blue,
    footer-link-color: white,
    footer-link-active-color: $sba-grey-two,
    reset-modal-background: $sba-light-grey,
    intro-background-color: $sba-background-grey,
    excursion-section-background-color: $sba-white,
    journey-resume-background-color: $sba-white,
    journey-item-header-color: $sba-dark-grey,
    journey-completed-row-color: $sba-dark-grey,
    journeys-background-color: $sba-background-grey,
    dashboard-progress-background-color: white,
    event-footer-buttons-hover: $sba-grey-three,
    event-footer-right-active-bg: $sba-blue,
    event-footer-right-active-color: $sba-white,
    event-footer-right-hover-bg: $sba-grey-two,
    event-footer-right-hover-color: $sba-grey-three,
    event-footer-left-bg: $sba-grey-one,
    event-footer-left-active-bg: $sba-grey-two,
    event-footer-left-active-color: $sba-grey-three,
    event-footer-left-hover-bg: $sba-grey-two,
    video-player-controls-bg: $sba-dark-grey,
    video-player-controls-captions-color: $sba-gold,
    video-player-controls-icon: $sba-white,
    video-player-controls-hover: $sba-gold,
    video-player-controls-border-hover: grey,
    video-player-controls-duration: $sba-white,
    video-player-controls-volume-slider-hover-border: grey,
    video-player-controls-scrubber-tooltip-border: rgba(108, 108, 108, 0),
    video-player-controls-scrubber-tooltip-border-top: #6c6c6c,
    slider-background: $slider-background,
    slider-lower: $sba-blue,
    slider-upper: $slider-background,
    slider-progress: $sba-gold,
    slider-thumb-bg: $sba-white,
    event-container-bg: $sba-white,
    event-header: $sba-dark-grey,
    event-header-button-color: $sba-navy,
    event-header-button-bg: white,
    event-header-button-focus-bg: $sba-grey-one,
    event-header-button-focus-border: $sba-grey-one,
    event-podcast-container-button-focus: #96dbfa,
    event-tip-box-bg: $sba-white,
    event-tip-box-border: $sba-blue,
    event-tip-box-title-color: $sba-blue,
    event-button-gold-bg: $sba-gold,
    event-h3-color: rgb(11, 47, 107),
    event-h3-company-subheader-color: $sba-grey-three,
    event-header-references-color: $sba-grey-three,
    event-path-content-table-color: $sba-white,
    event-path-content-table-bg: $sba-blue,
    event-path-content-table-tr-even: $sba-background-grey,
    transcript-container-bg: $sba-white,
    transcript-download-button: $sba-navy,
    transcript-download-bg: white,
    transcript-download-hover: $sba-grey-one,
    transcript-content-wrapper-a: $sba-blue,
    transcript-menu-border-bottom: $sba-white,
    transcript-menu-item: $sba-grey-three,
    transcript-menu-item-border: $sba-white,
    transcript-menu-item-active-border: $sba-blue,
    transcript-menu-item-hover-border: $sba-grey-one,
    left-navigation-bg: $sba-white,
    active-nav-number-circle: $sba-navy,
    active-nav-number-circle-bg: $sba-gold,
    nav-number-circle-color: $sba-navy,
    nav-number-circle-bg: $sba-white,
    nav-number-circle-completed-color: $sba-white,
    nav-number-circle-completed-bg: $sba-navy,
    ln-excursion-title: $sba-navy,
    ln-excursion-content-bg: $sba-white-two,
    excursion-name-progress: $sba-grey-three,
    topic-accordion-placeholder-bg: white,
    event-list-active-event: $sba-dark-grey,
    event-list-item: $sba-navy,
    event-list-key-takeaway-bg: white,
    event-list-item-extra: $sba-grey-three,
    event-list-item-active-bg: $sba-grey-one,
    event-list-item-icon: $sba-navy,
    event-list-completed-icon: $sba-blue,
    login-form-primary-text: $sba-blue,
    login-form-remember-label: $sba-grey-three,
    login-form-forgot-password: black,
    login-form-forgot-password-hover-bg: white,
    login-form-forgot-password-focus-bg: transparent,
    numbered-circle-color: $sba-white,
    numbered-circle-bg: $sba-blue,
    numeric-question-label: white,
    password-validation: $sba-grey-three,
    password-validation-validated: $sba-blue,
    navbar-menu-header-a: $sba-blue,
    navbar-dropdown-header: $sba-white,
    navbar-dropdown-menu-bg: $sba-white,
    navbar-ui-menu-bg: $sba-navy,
    navbar-menu-text: $sba-white,
    navbar-journeys-bg: $sba-navy,
    navbar-journeys-active-bg: $sba-blue-two,
    navbar-journeys-active-color: $sba-white,
    navbar-journeys-active-hover-bg: $sba-blue,
    navbar-journeys-active-a-color: $sba-dark-grey,
    navbar-journeys-active-menu-bg: $sba-white-two,
    navbar-journeys-active-item: $sba-dark-grey,
    navbar-journeys-active-item-visible-bg: $sba-white,
    navbar-search-button-color: $sba-navy,
    navbar-search-button-bg: $sba-gold,
    navbar-register-button-bg: $sba-navy,
    navbar-register-login-button-bg: $sba-blue,
    navbar-register-button-color: $sba-white,
    navbar-register-button-hover-bg: $sba-white,
    navbar-register-button-hover-color: $sba-navy,
    navbar-helpdesk-link-bg: $sba-navy,
    navbar-helpdesk-link-color: $sba-blue-two,
    navbar-user-button: $sba-white,
    navbar-logout-link-color: $sba-navy,
    navbar-logout-link-bg: $sba-white,
    navbar-active-topic-item-bg: $sba-light-grey,
    navbar-active-topic-item-link-hover-bg: $sba-light-grey,
    skiplink-active-bg: $sba-navy,
    skiplink-active-color: $sba-white,
    navbar-navigation-stie-search-bg: $sba-navy,
    navbar-dropdown-navigation-menu-bg: $sba-white,
    navbar-simple-dropdown-bg: $sba-light-grey,
    navbar-simple-dropdown-active-bg: $sba-blue-two,
    navbar-simple-dropdown-active-item-bg: $sba-blue,
    navbar-simple-dropdown-active-item-color: $sba-white,
    navbar-simple-dropdown-item-color: $sba-dark-grey,
    path-progress-color: $sba-navy,
    path-progress-bg: $sba-white,
    path-progress-icon: $sba-gold,
    path-progress-meta: $sba-grey-three,
    profile-bg: white,
    profile-box-shadow: 0 1px 2px -2px #b2b9c4,
    profile-header: $sba-navy,
    profile-resend-verification: $sba-blue,
    profile-modal-note: $sba-blue,
    profile-modal-emphasis: black,
    optional-form-color: $sba-navy,
    optional-form-bg: $sba-grey-one,
    registration-complete-success: $sba-blue,
    reset-password-bg: $sba-light-grey,
    search-button-applied-filter-bg: white,
    search-button-add-filters: $sba-white,
    search-button-add-filters-active-bg: $sba-blue,
    search-border-color: $sba-grey-one,
    search-item-bg: $sba-white,
    search-pagination: $sba-blue-three,
    search-pagination-a-active: $sba-blue,
    search-header: $sba-navy,
    search-facet-checkbox: $sba-blue,
    share-modal-button-color: $sba-navy,
    share-modal-button-bg: white,
    share-modal-button-border: $sba-navy,
    share-modal-button-hover-bg: $sba-grey-one,
    share-modal-button-hover-border: $sba-grey-one,
    share-modal-p-color: $sba-navy,
    share-modal-share-copy-button: $sba-white,
    share-modal-share-copy-button-bg: $sba-blue,
    share-modal-facebook: $sba-facebook-blue,
    share-modal-twitter: $sba-twitter-blue,
    share-modal-linkedin: $sba-linkedin-blue,
    signup-form-header: black,
    signup-form-gray-bg: $sba-light-grey,
    signup-form-in-business-text: $sba-blue,
    signup-form-business-question-title: $font-color-darker,
    timeout-warning: $sba-navy,
    timeout-warning-logout-button-bg: $sba-grey-one,
    timeout-warning-logout-button-focus-bg: $sba-grey-two,
    touring-modal-circle-active: $sba-blue,
    touring-modal-header: $sba-blue,
    touring-modal-content-right-active-color: $sba-white,
    touring-modal-content-right-active-bg: $sba-blue,
    touring-modal-content-right-hover-color: $sba-grey-three,
    touring-modal-content-right-hover-bg: $sba-grey-two,
    touring-modal-carousel-circle: $sba-grey-one,
    touring-modal-carousel-circle-active: $sba-blue,
    touring-modal-hover-disabled-button-color: $sba-white,
    touring-modal-hover-disabled-button-bg: $sba-blue,
  ),
);

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}
