@import "../../assets/styles/base";

.number-circle-ring {
  border-radius: 50%;
  display: inline-block;
  font-weight: bolder;

  background: inherit;

  .number-circle {
    border-radius: 50%;
    display: inline-block;
    font-weight: bolder;
    border: none;
    text-align: center;

    font-size: 16px;

    @include themify($themes) {
      color: themed("numbered-circle-color");
      background-color: themed("numbered-circle-bg");
    }

    .number-circle-text {
      margin-top: -3px;
      width: 33px;
      height: 33px;
      padding-top: 6px;
    }
  }
}
