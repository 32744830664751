@import "../../assets/styles/base";

.breadcrumb-header-grid {
  box-shadow: $box-shadow-two;

  @include themify($themes) {
    background-color: themed("breadcrumb-header-grid-background-color");
  }

  .breadcrumb-header-row {
    padding-top: 0px !important;
  }

  .breadcrumb-row {
    margin-top: 20px !important;
    margin-bottom: 6px;
  }

  .header-row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .header-content {
      color: $sba-blue !important;
      font-size: 29px;
      text-align: left;

      @include themify($themes) {
        color: themed("breadcrumb-header-content-color") !important;
      }
    }
  }

  .description-row {
    font-size: 14px;

    @include themify($themes) {
      color: themed("font-color-darker");
    }
  }
}

.ui.grid > .row > .column.share-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .button {
    margin-top: 1em !important;
  }
}

@media only screen and (max-width: $mobile-width) {
  .breadcrumb-header-grid {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: -3rem !important;

    .breadcrumb-header-row {
      padding-bottom: 1em;

      & > .column {
        padding-bottom: 0 !important;
      }
      .breadcrumb-row {
        margin-top: 50px !important;
        margin-bottom: 0;
        & > .ui.grid {
          margin-top: -3rem;
          & > .column {
            padding-top: 1em;
            padding-bottom: 1em !important;
          }
        }
      }
    }
  }
}
