@import "../../assets/styles/base";

.container.media-transcript-container {
  margin-top: 20px;
  margin-right: 0 !important;
  margin-left: 0 !important;

  @include themify($themes) {
    background: themed("transcript-container-bg");
    border: themed("main-border");
    box-shadow: themed("main-box-shadow");
  }

  #mle-comments {
    margin: 0px 0 20px 0;
    .mle-comments-header {
      margin-top: 5px;
    }
  }

  #transcript-wrapper {
    margin: 0px 0 20px 0;

    .row.transcript-header-wrapper {
      border-bottom: $main-border;

      .transcript-header-column {
        padding-left: 0;

        .transcript-header {
          display: inline-block;
          margin-bottom: 0;
          margin-left: 35px;
          margin-top: 5px;

          @media only screen and (max-width: $mobile-width) {
            margin-left: 1em;
          }
        }

        .download-button {
          border-radius: 7px !important;

          @include themify($themes) {
            background-color: themed("transcript-download-bg") !important;
            border: 2px solid themed("transcript-download-button") !important;
            color: themed("transcript-download-button") !important;
          }

          &:hover,
          &:focus {
            @include themify($themes) {
              background-color: themed("transcript-download-hover") !important;
              border: 2px solid themed("transcript-download-hover") !important;
            }
          }
        }
      }
    }
    .row.transcript-content-wrapper {
      padding-top: 0px;

      @media only screen and (max-width: $mobile-width) {
        padding-left: 1em;
        padding-right: 1em;
      }

      .transcript-content.column {
        padding-left: 0;

        .transcript-content-text {
          padding: 40px;

          @media only screen and (max-width: $mobile-width) {
            padding: 40px 0;
          }

          font-size: 19px;
          line-height: 32px;

          &.references {
            font-size: 16px;
          }

          a {
            word-wrap: break-word;
            font-weight: bold;

            @include themify($themes) {
              color: themed("transcript-content-wrapper-a");
            }
          }

          p {
            margin: 0 0 1em;
            word-wrap: break-word;
          }

          ul {
            margin-top: 0;
            padding-left: 19px;
          }
        }
      }
    }

    div.transcript-about-panel {
      h2,
      h3,
      h4 {
        margin: 0;
      }

      h2 {
        margin-top: -6px;
        font-size: 21px;
      }

      h3,
      h4 {
        font-weight: normal;
        font-style: italic;
      }

      h4 {
        margin-bottom: 15px;
      }
    }
  }
}

#media-transcript-menu {
  position: absolute;
  bottom: 0px;
  margin-bottom: 0;

  padding-left: 0;
  padding-right: 0;
  margin-left: 1.5em;
  margin-right: 1.5em;

  @include themify($themes) {
    border-bottom: 2px solid themed("transcript-menu-border-bottom");
  }

  .item {
    font-size: 16px;
    margin-right: 30px;
    padding-right: 0;
    padding-left: 0;
    border-bottom-width: 4px;

    font-weight: bold;
    padding-bottom: 20px;

    @include themify($themes) {
      color: themed("transcript-menu-item");
      border-color: themed("transcript-menu-item-border");
    }

    .transcript-item-text {
      text-align: center;
      &:first-letter {
        text-transform: capitalize;
      }
    }

    &.active,
    &.active:hover,
    &.active:focus {
      @include themify($themes) {
        border-color: themed("transcript-menu-item-active-border");
      }
    }

    &:hover,
    &:focus {
      @include themify($themes) {
        border-color: themed("transcript-menu-item-hover-border");
      }
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  #media-transcript-menu {
    position: inherit;
  }
}

@media only screen and (max-width: $mobile-width) {
  #media-transcript-menu {
    justify-content: left;
    margin-left: 0;

    .item {
      margin-right: 1.3em;
      margin-left: 1.3em;
      padding: 20px 0;
    }
  }

  #transcript-wrapper {
    .ui[class*="right floated"].button {
      margin-right: 1em;
    }
    .transcript-content-text {
      word-wrap: break-word;
    }

    .transcript-about-panel {
      margin: 0 !important;
    }
  }

  .media-transcript-container {
    margin-bottom: 20px;
  }
}
