@import "../../assets/styles/base";

#optional-form {
  .numeric-question {
    margin-top: 0;

    .row {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .column {
      margin-left: 0;
      margin-right: 0;
    }

    input {
      height: 33px;

      @media only screen and (max-width: $mobile-width) {
        margin-left: 1em;
      }
    }

    .message {
      font-weight: bold;
      padding: 7px 15px;
    }

    .percent.icon {
      font-size: 14px;
      margin-right: 0;
      margin-top: 2px;
    }

    .numeric-label {
      font-size: 20px;
      display: flex;
      align-items: center;
      height: 33px;
      margin-left: -23px;
      padding-left: 0;

      @include themify($themes) {
        background-color: themed("numeric-question-label");
      }
    }

    .numeric-question-text {
      @media only screen and (max-width: $mobile-width) {
        margin-left: 1em;
      }
    }
  }
}
