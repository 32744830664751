@import "../../assets/styles/base";

#optional-form {
  h2 {
    margin-bottom: 0;
  }

  p.optional {
    margin-bottom: 15px;
  }

  .optional-form-bottom {
    margin-top: 50px;
    margin-bottom: 33px;
  }

  .optional-form-bottom-divider {
    margin-top: 40px;
  }

  .previous {
    @include themify($themes) {
      color: themed("optional-form-color");
      background-color: themed("optional-form-bg");
    }
  }

  .next {
    width: 93px;
  }

  .button-row {
    padding-bottom: 0;
    text-align: right;
  }
}

@media only screen and (max-width: $mobile-width) {
  #optional-form {
    .ui.grid.optional-button-row-grid {
      margin-bottom: 10px;
    }

    .column {
      padding: 0 !important;
    }
  }
}
