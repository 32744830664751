@import "../../assets/styles/base";

#touring-modal {
  width: 80%;
  max-width: 700px;

  .circle.icon.active {
    @include themify($themes) {
      color: themed("touring-modal-circle-active");
    }
  }

  .touring-content-header .header {
    @include themify($themes) {
      color: themed("touring-modal-header");
    }
  }

  .content:focus {
    outline: none;
  }

  .inactive {
    display: none;
  }

  .row {
    align-items: flex-start;
  }

  .touring-content {
    padding-left: 15px !important;
    padding-right: 15px !important;

    &:focus {
      outline: none;
    }

    img {
      margin-left: auto;
      margin-right: auto;
    }

    .desktop-content-row {
      margin-top: 0;

      .styled-text {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }

    .button {
      margin-left: auto;
      margin-right: auto;

      &:focus, &:active {
        background-color: $sba-blue;
        color: $sba-white;
        text-decoration: underline;
      }
    }

    &.right {
      padding-left: 15px !important;
      padding-right: 41px !important;
      text-align: center;

      &:active {
        text-decoration: none;

        @include themify($themes) {
          color: themed("touring-modal-content-right-active-color");
          background-color: themed("touring-modal-content-right-active-color");
        }
      }

      &:hover,
      &:focus {
        text-decoration: underline;

        @include themify($themes) {
          color: themed("touring-modal-content-right-hover-color");
          background-color: themed("touring-modal-content-right-hover-color");
        }
      }
    }
  }

  .touring-mobile-image-row {
    width: 100% !important;
    margin: 1em auto 0 auto;
  }

  .carousel-row,
  .carousel-action {
    width: 100% !important;
    display: flex;
    margin: 1em auto 1em auto;
  }

  #carousel {
    margin-left: auto;
    margin-right: auto;

    .icon.circle {
      @include themify($themes) {
        color: themed("touring-modal-carousel-circle");
      }
    }

    .icon.circle.active {
      @include themify($themes) {
        color: themed("touring-modal-carousel-circle-active");
      }
    }
  }
}

@media (hover: none) {
  #touring-modal {
    .touring-content {
      .button:hover {
        text-decoration: none;

        @include themify($themes) {
          color: themed("touring-modal-hover-disabled-button-color");
          background-color: themed("touring-modal-hover-disabled-button-bg");
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none) {
  @mixin ie11-image-row {
    max-width: 100%;
    flex-shrink: 0;
  }
  .desktop-content-row {
    .row {
      @include ie11-image-row;
    }
  }

  .touring-mobile-image-row {
    @include ie11-image-row;
    img {
      flex-shrink: 0;
    }
  }
}
