@import "../../assets/styles/base";

.path-progress {
  padding: 10px 18px 10px 18px;
  border: $main-border;
  border-radius: 5px;
  margin-bottom: 10px !important;
  box-shadow: $main-box-shadow;

  @include themify($themes) {
    background-color: themed("path-progress-bg");
    color: themed("path-progress-color");
  }
}

.ui.grid > .row {
  .path-progress-icon {
    font-size: 40px;

    @include themify($themes) {
      color: themed("path-progress-icon");
    }
  }

  .path-progress-image-row {
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .path-progress-header-row {
    padding-top: 0;
    padding-bottom: 5px;
  }

  .path-progress-bar-row {
    padding: 0;

    .path-progress-bar {
      margin-bottom: 0;
    }
  }

  .path-progress-footer-row {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .path-progress-column {
    #path-progress-bar-text {
      font-size: 16px;
    }

    .ui.mini.image {
      margin-bottom: 0px;
    }
  }

  .path-progress-column,
  .path-progress-footer-row {
    text-align: center;
  }
}

#path-progress-track-column {
  padding-left: 20%;

  #path-progress-image {
    margin-bottom: 0;
  }

  #path-progress-text {
    padding-top: 6px;
  }
}

.path-progress-meta {
  font-style: italic;
  font-size: 16px;

  @include themify($themes) {
    color: themed("path-progress-meta");
  }
}

.path-progress-register-row {
  padding-top: 0 !important;
}

@media only screen and (max-width: $mobile-width) {
  #path-progress-image-div {
    padding-left: 20%;
  }

  #path-progress-text {
    padding-right: 20%;
  }
}
