$sba-navy: #002e6d; // primary-nav-color
$sba-blue: #2d78c9; // primary-title-color
$sba-blue-two: #005999; // highlight-color
$sba-secondary-blue: #007dbc; // secondary-title-color
$sba-blue-three: #2a70bb; // pagination-color
$sba-gold: #ffab3b; // alternate-button-color
$sba-orange: #fe664c; // alternate-hover-color
$sba-light-grey: #f5f5f5; // form-menu-background
$sba-grey-one: #ced6e1; // secondary-background-color
$sba-grey-two: #b2b9c4; // footer-background-color
$sba-grey-three: #61656b; // alternate-background-color
$sba-dark-grey: #34363a; // dark-font-color
$sba-white: #ffffff; // primary-background-color
$sba-white-two: #f2f3f4; // nav-background
$sba-facebook-blue: #3b5998;
$sba-twitter-blue: #55acee;
$sba-linkedin-blue: #0077b5;
$sba-background-grey: #edf1f6;
$box-shadow-color: rgba(34, 36, 38, 0.15);
$border-color: rgba(34, 36, 38, 0.15);
$font-color: rgba(0, 0, 0, 0.68);
$font-color-darker: rgba(0, 0, 0, 0.87);
$semantic-hr-color: #22242626;
$outline-color: #73a3f8;
$body-background-color: #fafbfd;
$box-shadow-two-color: rgba(0, 0, 0, 0.1);
$slider-background: #d3d3d3;

// colors used for bullets only
$sba-teal: #34bdc5;
$sba-dark-teal: #0a7d84;
$sba-light-blue: #a8e7ee;
$sba-dark-orange: #c7513d;
$sba-red: #cc0000;
$sba-black: #34363a;

:export {
  sbaNavy: $sba-navy;
  sbaBlue: $sba-blue;
  sbaBlueTwo: $sba-blue-two;
  sbaBlueThree: $sba-blue-three;
  sbaSeconaryBlue: $sba-secondary-blue;
  sbaTeal: $sba-teal;
  sbaDarkTeal: $sba-dark-teal;
  sbaLightBlue: $sba-light-blue;
  sbaGold: $sba-gold;
  sbaOrange: $sba-orange;
  sbaDarkOrange: $sba-dark-orange;
  sbaRed: $sba-red;
  sbaLightGrey: $sba-light-grey;
  sbaGreyOne: $sba-grey-one;
  sbaGreyTwo: $sba-grey-two;
  sbaGreyThree: $sba-grey-three;
  sbaDarkGrey: $sba-dark-grey;
  sbaWhite: $sba-white;
  sbaWhiteTwo: $sba-white-two;
  sbaFacebookBlue: $sba-facebook-blue;
  sbaTwitterBlue: $sba-twitter-blue;
  sbaLinkedinBlue: $sba-linkedin-blue;
  sbaBackgroundGrey: $sba-background-grey;
  boxShadowColor: rgba(34, 36, 38, 0.15);
  borderColor: rgba(34, 36, 38, 0.15);
}
