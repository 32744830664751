//Global styles to be applied to any content served by Canvas/CMS
.quote-source,
.author {
  font-size: 16px;
  text-align: right;
  margin-top: -1em;
  margin-left: 40px;
  margin-right: 40px;
}

.learning-event-body {
  .ui.divider {
    margin-bottom: 33px;
  }

  .ui.divider.divider-top-padding {
    margin-top: 33px;
  }
}

.screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
