@import "../../assets/styles/base";

.verify-your-email {
  text-align: center !important;
}

.success-message {
  h2 {
    text-align: center;
    font-weight: 600;

    @include themify($themes) {
      color: themed("registration-complete-success");
    }
  }
}

.logo-congrats {
  padding-top: 15px;
}
