@import "../../assets/styles/base";

.passwordValidation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 2em;
  grid-gap: 0.5em;

  @include themify($themes) {
    color: themed("password-validation");
  }

  .validated {
    @include themify($themes) {
      color: themed("password-validation-validated");
    }
  }
}
