#optional-form {
  .numeric-question-with-label {
    margin-top: 0;

    .row {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .message {
      font-weight: bold;
      padding: 7px 15px;
      margin-top: 13px;
    }
    
    .percent.icon {
      font-size: 14px;
	    margin-right: 0;
      margin-top: 2px;
    }

    .numeric-label {
      font-size: 15px;
      font-weight: 600;

      .dropdown .menu .item.active {
        font-weight: 600;
      }
    }
  }

  .numeric-question-with-label {
    .numeric-label {
   	  margin-left: -30px;
 	    border-top-left-radius: 0;
 	    border-bottom-left-radius: 0;
 	    width: fit-content;

      .dropdown .menu {
        left: -13px;
        top: 25px;
      }
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .numeric-question-with-label {
    .numeric-label-column.column {
      align-items: flex-start;
    }
  }
}
