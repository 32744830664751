@import "../../assets/styles/base";

div.homepage-container {
  width: 100%;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 50px;

  div.ui.grid {
    margin: 0;
  }

  .homepage-container-title {
    .title {
      font-weight: bold;
    }

    .row {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    width: 972px;
  }

  @media only screen and (max-width: 360px) {
    width: 100%;
  }
}
