@import "../../assets/styles/base";

#homepage {
  .card-grid-container {
    background: $sba-white;
    padding-top: 20px;
  }

  .card-grid {
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 50px;

    &::after {
      width: 280px;
    }

    .card-grid-card {
      margin-left: 15px;
      margin-right: 15px;
      padding: 40px 0 30px 0;

      @media only screen and (max-width: $mobile-width) {
        padding: 20px 0 0 0;
        margin-top: 0;
        margin-bottom: 0;
      }

      box-shadow: none;
      width: 250px;

      img {
        background: none;
      }

      .card-grid-card-content {
        border-top: none;
        padding: 15px 30px 0px 30px;
        text-align: center;

        .card-grid-card-header {
          margin: 0 0 0 0;
        }

        .card-grid-card-description {
          font-size: 18px;
        }

        .header,
        .description {
          color: $sba-dark-grey !important;
        }
      }
    }

    div.ui.grid {
      margin: 0;
    }

    @media only screen and (max-width: 1310px) {
      justify-content: center;
    }

    @media only screen and (min-width: 1200px) {
      width: 972px;
    }

    @media only screen and (max-width: 360px) {
      width: 100%;
    }
  }
}
