@import "../../assets/styles/base";

/* LeftNavigation */
.left-navigation-container {
  border-radius: 0.28571429rem;
  padding: 1rem 0 !important;
  margin-top: 0px !important;
  margin-bottom: 40px;

  @include themify($themes) {
    background: themed("left-navigation-bg");
    border: themed("main-border");
    box-shadow: themed("main-box-shadow");
  }

  &.topic-path-active {
    padding: 1rem 0 0 0 !important;

    .ln-excursion-title {
      margin-bottom: 1rem;
    }
  }

  .topic-number-circle-wrapper {
    background: inherit;
    width: 44px;
    height: 44px;
    padding: 6px;

    &.active-nav-item {
      background-image: url('/img/nav_indicator_44px.gif');
      background-position: center;

      .topic-number-circle {
        background-color: inherit !important;

        @include themify($themes) {
          color: themed("active-nav-number-circle");
        }

        &.completed-excursion {
          background-color: inherit;

          @include themify($themes) {
            color: themed("active-nav-number-circle");
          }
        }
      }
    }

    .topic-number-circle {
      border: none;
      border-radius: 50%;
      display: inline-block;

      font-weight: bolder;
      font-size: 16px;
      text-align: center;

      width: 30px;
      height: 30px;
      padding: 6px;

      margin-top: 1px;
      margin-left: 1px;
      margin-right: 1rem;
      position: absolute;

      @include themify($themes) {
        background: themed("nav-number-circle-bg");
        color: themed("nav-number-circle-color");
      }

      &.completed-excursion {
        @include themify($themes) {
          background: themed("nav-number-circle-completed-bg");
          color: themed("nav-number-circle-completed-color");
        }

        & .topic-number-circle-text {
          margin-top: -2px;
        }
      }

      .topic-number-circle-text {
        margin-top: -4px;
      }
    }
  }

  .sidebar-title-name-row {
    vertical-align: middle;
  }

  .header i.icon.big {
    float: left;
  }

  .ln-excursion-title {
    font-size: 1em !important;
    font-weight: bold;
    padding: 1rem !important;
    height: 100%;

    @include themify($themes) {
      color: themed("ln-excursion-title") !important;
    }

    .excursion-name {
      height: 100%;
      vertical-align: top;
      display: inline-block;
      line-height: 1.3;
      padding-bottom: 10px;
      padding-top: 10px;

      &:hover,
      &.active-event {
        text-decoration: underline;
      }
    }

    .left-nav-item {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .excursion-name-progress {
      float: right;
      font-weight: normal;

      @include themify($themes) {
        color: themed("excursion-name-progress");
      }
    }
  }

  .ln-excursion-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    @include themify($themes) {
      background-color: themed("ln-excursion-content-bg") !important;
    }

    .ui.divided.items > .item:last-child {
      padding-bottom: 1em !important;
    }

    .ui.divided.items > .item:first-child {
      padding-top: 1em !important;
    }

    .item-content {
      margin-top: -10px !important;
    }
  }

  .topic-accordion-placeholder-item-first {
    padding: 1em;

    @include themify($themes) {
      background-color: themed("topic-accordion-placeholder-bg");
    }
  }

  .topic-accordion-placeholder-item {
    padding: 1em;
    border-top: 1px solid rgba(34, 36, 38, 0.15);

    @include themify($themes) {
      background-color: themed("topic-accordion-placeholder-bg");
    }
  }

  .event-list-accordion-item.active-event {
    .extra {
      @include themify($themes) {
        color: themed("event-list-active-event") !important;
      }
    }
  }

  .checkmark-completed-container {
    margin-bottom: auto;
  }

  .event-list-accordion-item {
    font-weight: 600;
    cursor: pointer;
    padding-left: 40px !important;

    display: flex;
    align-items: center;

    @include themify($themes) {
      color: themed("event-list-item") !important;
    }

    &.key-takeaway {
      @include themify($themes) {
        background-color: themed("event-list-key-takeaway-bg");
      }
    }

    .description {
      padding-right: 20px;

      @include themify($themes) {
        color: themed("event-list-item") !important;
      }
    }

    .extra {
      margin: 0 !important;

      @include themify($themes) {
        color: themed("event-list-item-extra") !important;
      }
    }

    .tiny.image {
      width: 40px;
      height: 40px;
    }

    .checkmark-completed-container {
      padding-top: 5px;

      .checkmark-completed {
        margin-right: 25px;
      }
    }

    &:hover,
    &.active-event {
      text-decoration: none;

      @include themify($themes) {
        background-color: themed("event-list-item-active-bg") !important;
      }

      .content > .description {
        text-decoration: underline;
      }

      .extra {
        text-decoration: none;
      }
    }

    .event-list-item-icon {
      padding-right: 1.5em !important;
      padding-left: 1em !important;

      @include themify($themes) {
        color: themed("event-list-item-icon");
      }
    }

    .completed-event-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 40px;
        text-decoration: none;
        font-weight: bolder;
        font-size: 1.25em;

        @include themify($themes) {
          color: themed("event-list-completed-icon");
        }
      }
    }

    .completed-event-icon-wrapper + .content {
      padding-left: 1.5em !important;
    }
  }
}
