.teaser-component {
  .header {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  
  .row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .image {
    margin-left: auto;
    margin-right: auto;
  }
}