@import "../../assets/styles/base";

#external-site-modal {
  font-size: 18px;

  h2 {
    @include themify($themes) {
      color: themed("homepage-button-background");
    }
  }
  a {
    overflow-wrap: break-word;

    &.ui.primary.button:focus {
      color: $sba-white;
      background-color: $sba-blue;
    }
  }

  #header-row {
    padding-bottom: 0;
  }

  #header-divider-row {
    padding-top: 0;
    padding-bottom: 0;

    .ui.divider {
      @include themify($themes) {
        border-top: 2px solid themed("external-site-modal-divider");
        border-bottom: 2px solid themed("external-site-modal-divider");
      }
    }
  }

  #disclaimer-text-row {
    padding-top: 0;
  }
}
