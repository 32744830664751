@import "../../assets/styles/base";

//Free Form Page HTML
//This styling should be specifically for the content being entered
//and not for the learning event page itself
.learning-event-body {
  a {
    word-wrap: break-word;
  }

  .not-completed-message {
    color: rgb(52, 54, 58);
    font-size: 18px;
  }
}
