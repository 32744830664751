@import "../../assets/styles/base";
@import "../../assets/styles/themes";

/* Excursions */
.topic-content-view {
  @include themify($themes) {
    background: themed("excursion-content-view-background");
  }

  margin-bottom: 20px;
  border: $main-border;
  border-radius: 0.28571429rem;

  .content {
    padding-top: 0 !important;
  }
}

.topic-content-select {
  padding-bottom: 2em !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

/* TopicContentItem */
.events-list-accordion-title {
  display: none;
}

.topic-content-item-group {
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .topic-content-item {
    border-bottom: $main-border !important;
    margin-top: 0 !important;

    padding: 2em !important;
  }

  .topic-content-item > .content {
    padding: 1em !important;
    padding-top: 0 !important;
    padding-left: 28px !important;
    padding-right: 0px !important;
  }

  .topic-content-item-header {
    padding-top: 6px !important;
    font-size: 1.75em;
    color: $sba-grey-three !important;
  }

  .topic-content-collapse-button {
    float: left;
  }
}

/* ExcursionPathList */
.topic-content-event-grid {
  display: flex;
  margin-top: 0 !important;
  margin: 0 !important;
  padding: 1em 1em 1em 2em !important;
  border-bottom: $main-border !important;
  &:hover {
    @include themify($themes) {
      background-color: themed("excursion-content-grid-hover") !important;
    }
  }

  .icon-column {
    width: 75px;
    flex: 0 0 75px;
    padding: 0 !important;
  }
}

.topic-content-event-grid-icon {
  color: $sba-navy;
  width: 50px;
}

.topic-content-event-grid-details {
  flex: 1;
  padding-top: 1px !important;
}

.topic-content-event-grid-header {
  font-size: 18px;
  font-weight: 600;

  @include themify($themes) {
    color: themed("excursion-content-grid-header-color") !important;
  }

  &:hover {
    text-decoration: underline;

    @include themify($themes) {
      color: themed("excursion-content-grid-header-hover") !important;
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .topic-content-view {
    border-radius: 0;

    .topic-content-item-group {
      .topic-content-item {
        padding: 16px !important;
        .content {
          padding: 0 !important;
        }
      }
    }

    .topic-content-event-grid {
      padding: 32px 16px !important;

      &:first-child {
        padding-top: 0 !important;
      }

      .image.topic-content-event-grid-icon {
        width: 50px;
        height: 50px;
      }
    }
  }

  #sba-main-row .column.topic-content-event-grid-details {
    padding-left: 20px !important;
  }
}
