@import "../../assets/styles/base";
$journey-card-width: 320px;

#journeys-grid {
  margin-bottom: 100px;

  #journeys-row {
    margin-top: 25px;
  }
}

#journeys-header {
  margin-right: 45px;
}

#journey-cards {
  margin-top: 25px;

  h3 {
    font-weight: bold;
    width: 100%;
    display: block;
  }
}

.journey-card.ui.card {
  width: $journey-card-width;
  margin-left: 10px;
  margin-right: 45px;

  .ui.placeholder {
    animation: placeholderShimmer 4s linear;
    animation-iteration-count: infinite;
  }
}

.journey-item-header {
  @include themify($themes) {
    color: themed("journey-item-header-color");
  }
}

.journey-progress-bar-row {
  padding-bottom: 0 !important;
}

.journey-completed-row {
  padding-top: 0 !important;

  @include themify($themes) {
    color: themed("journey-completed-row-color");
  }
}

@media only screen and (max-width: 1199px) and (min-width: $mobile-width) {
  #journey-resume-component {
    width: 770px;
    padding-left: 35px;
  }
}

@media only screen and (max-width: $mobile-width) {
  #journeys-grid {
    margin-left: 0;
    margin-right: 0;
  }

  #journeys {
    padding-left: 0;
    padding-right: 0;
  }

  #journeys-header {
    width: 100%;
  }

  .journey-progress.ui.grid {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  #journey-cards {
    padding-left: 0px;
    padding-right: 0px;

    .journey-card.ui.card {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      &:last-of-type {
        margin-bottom: 50px;
      }
    }
  }
}
