@import "../../assets/styles/base";

.event-content-column {
  width: 90%;

  .event-title-container {
    display: flex;
  }

  .checkmark-completed-container {
    margin-bottom: auto;
    margin-left: auto;
  }

  .event-type {
    padding-bottom: 10px;
    font-style: italic;

    @include themify($themes) {
      color: themed("excursion-content-type-color");
    }
  }
}

.checkmark-completed {
  float: right;
}
