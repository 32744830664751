@import "../../assets/styles/base";

.button.read-more-button {
  @include button-as-link;
  padding: 0 10px;

  &:hover,
  &:active,
  &:focus {
    @include button-as-link;

    @include themify($themes) {
      border: solid 2px themed("outline-color");
    }
  }
}
