@import "base.scss";

/* ------ Global Internet Explorer 11 overrides ------- */

@media all and (-ms-high-contrast: none) {
  .modal {
    // Centers the semantic modals in IE
    position: static !important;

    // Flex is incredibly inconsistent in IE, for the modals with image content in semantic
    // Adding the width values sets the inner modal styles on these wrappers correctly
    .image.content {
      .image {
        width: 100%;
      }
      .description {
        width: 100% !important;
      }
    }
  }
}
