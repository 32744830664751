@import "../../assets/styles/base";

#journey-resume-component {
  margin-top: 0.7em;

  .journey-resume {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    @include themify($themes) {
      background-color: themed("journey-resume-background-color");
      box-shadow: 0px 1px 3px 0px themed("box-shadow-color"),
        0px 0px 0px 1px themed("box-shadow-color");
    }
  }

  .journey-resume-content {
    padding-top: 64px !important;
    padding-bottom: 20px !important;
  }

  .journey-resume-image {
    padding-left: 0 !important;
  }

  .resume-progress {
    margin: 0px 15px;
    border-radius: 7px;
    width: 100%;

    @include themify($themes) {
      border: themed("main-border");
      box-shadow: themed("main-box-shadow");
      background-color: themed("journey-resume-background-color");
      box-shadow: 0px 1px 3px 0px themed("box-shadow-color"),
        0px 0px 0px 1px themed("box-shadow-color");
    }

    .journey-completed-row {
      font-style: italic;
    }
  }

  .dashboard-progress {
    border-radius: 7px;
    padding: 20px;
    margin: 0px 15px;

    @include themify($themes) {
      border: themed("main-border");
      background-color: themed("dashboard-progress-background-color");
      box-shadow: 0px 2px 6px 0px themed("box-shadow-color"),
        0px 0px 0px 2px themed("box-shadow-color");
    }

    .dashboard-progress-title {
      font-size: x-large;
    }
    .feature-list-item {
      margin-bottom: 20px;
    }
    .register-button-row {
      padding-top: 0;
      padding-bottom: 0;

      button {
        width: 110px;
      }
    }
  }

  @media only screen and (max-width: $mobile-width) {
    margin-top: 0px;
  }

  @media only screen and (max-width: $tablet-width) {
    .login-button {
      margin-top: 15px;
    }
  }
}
