@import "../../assets/styles/base";

#fireside-chat-disclaimer {
  padding: 30px 45px;

  @media only screen and (max-width: $mobile-width) {
    padding: 30px 1em;
  }

  @include themify($themes) {
    color: themed("fireside-chat-disclaimer-color");
  }

  #disclaimer-heading {
    font-weight: bold;
  }
}
