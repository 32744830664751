@import "colors.scss";

div.modal.ui {
  > .content {
    padding: 3rem;
  }

  h1 {
    padding-bottom: 0.5rem;

    @include themify($themes) {
      color: themed("modals-header");
    }
  }

  .ui.form .field {
    margin: 1.5rem 0;
  }

  button.ui.icon.button.eli-modal-close-button {
    background: transparent;
    margin: -20px 0 0 0;
    padding: 2px 0px 0px 0px;
    text-align: right;
    height: 30px;
    border: 2px solid transparent;

    &:focus {
      @include themify($themes) {
        border: 2px solid themed("modals-close-button-focus-border");
      }
    }

    &:hover {
      text-decoration: underline;
      border: 2px solid transparent;
    }
  }

  .success-message span {
    font-weight: bold;

    @include themify($themes) {
      color: themed("modals-success-message");
    }
  }

  .success-message.row {
    padding: 0;
  }
}
