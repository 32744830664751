@import "../../assets/styles/base";

.profile {
  @include themify($themes) {
    background-color: themed("profile-bg");
  }
}

#profile-page-header {
  margin-top: 0;
  padding-top: 1em;
  padding-bottom: 1em;

  @include themify($themes) {
    background-color: themed("profile-bg");
    box-shadow: themed("profile-box-shadow");
    -webkit-box-shadow: themed("profile-box-shadow");
    -moz-box-shadow: themed("profile-box-shadow");
  }
}

#profile-header {
  outline: none;

  @include themify($themes) {
    color: themed("profile-header");
  }
}

#profile-name-header,
#profile-body {
  border: $main-border;
  border-radius: 0.28571429rem;
  padding-left: 50px;
  padding-right: 50px;

  #profile-name-header-container {
    padding-top: 25px;
    padding-bottom: 10px;
    h2 {
      text-align: left;

      @include themify($themes) {
        color: themed("profile-header");
      }
    }
    p {
      margin-bottom: 0;
    }
  }
}

#mobile-user-profile-content {
  padding: 20px;
  margin: 0 0 0 0;
  overflow-x: auto;

  #resend-verification {
    cursor: pointer;

    @include themify($themes) {
      color: themed("profile-resend-verification");
    }
  }
}

.mobile-profile-field-divider {
  padding-bottom: 20px;
}

#profile-body {
  display: none;
}

#edit-profile-modal {
  .ui.message {
    padding: 0.8em 1.5em;
  }
}

#edit-profile-header {
  font-size: 29px;
  padding-bottom: 10px;

  @include themify($themes) {
    color: themed("profile-header");
  }
}

.edit-profile-error-message,
.profile-load-error {
  margin-top: 25px;
  font-weight: bold;
}

#in-business-radio-buttons {
  margin-top: -20px;
  margin-bottom: 0px;
}

#user-profile {
  margin-top: 0px;
  padding-bottom: 150px;
}

#in-business-field {
  padding-top: 0;
  padding-bottom: 0;
}

#user-profile-content {
  padding-top: 10px;
  padding-bottom: 15px;

  #user-profile-headings {
    width: auto;
  }

  #resend-verification {
    margin-left: 20px;
    cursor: pointer;

    @include themify($themes) {
      color: themed("profile-resend-verification");
    }
  }
}

.change-password-msg {
  font-style: italic;
}
.profile-modal-container {
  .profile-modal-messaging {
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;

    .note {
      font-weight: bold;

      @include themify($themes) {
        color: themed("profile-modal-note");
      }
    }

    .profile-modal-emphasis {
      font-size: 20px;
      font-weight: bold;

      @include themify($themes) {
        color: themed("profile-modal-emphasis");
      }
    }
  }

  .profile-modal-button-container {
    float: right;
    padding-bottom: 26px;
    padding-top: 8px;
  }
}

.edit-form-button-group {
  float: right;
  padding-top: 8px;
  padding-bottom: 26px;

  .button {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .form-cancel-button {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.profile-button-group {
  float: right;
  padding-top: 10px;
  padding-bottom: 25px;
  width: 100%;

  .form-cancel-button {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.edit-form-button {
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

#business-question {
  &:focus-within {
    outline: solid 2px $outline-color;
  }
}

@media only screen and (max-width: $mobile-width) {
  .profile {
    margin-top: -3rem;
    padding: 0 0 0 0 !important;
  }

  #profile-page-header {
    div.mobile {
      padding-bottom: 0;
    }
  }

  #profile-header {
    @include themify($themes) {
      color: themed("profile-header");
    }
  }

  #profile-name-header-container {
    h2 {
      padding-left: 20px;
    }
  }

  .edit-form-button-group {
    float: unset;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;

    .button {
      display: block;
      width: 200px;

      margin-left: auto;
      margin-right: auto;
      &:not(:last-child) {
        margin-bottom: 16px;
        margin-right: auto;
      }
    }
  }
  .profile-modal-container {
    .profile-modal-button-container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .profile-button-group {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    padding-top: 0px;

    .button {
      display: block;
      float: none !important;
      margin-left: auto !important;
      margin-right: auto !important;
      width: 180px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
