@import "../../../../assets/styles/base";
#infographic-template {
  .block.quote {
    & > div.row.quote-wrapper {
      padding-left: 40px;
      padding-right: 40px;

      .attribution.right {
        float: right;
      }

      .attribution.left {
        float: left;
      }

      .quote-text {
        padding-left: 40px;
      }

      .styled-text {
        font-style: italic;
        text-align: center;

        &.font-size-x-small,
        &.font-size-medium,
        &.font-size-large {
          line-height: 1;
        }

        &.font-size-x-large {
          line-height: 0.8;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .quote-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
