@import "../../assets/styles/base";

.learning-event-wrapper {
  height: 100%;

  .container.learning-event-container {
    padding: 15px 0px 0px 0px;
    margin-top: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;

    @include themify($themes) {
      background: themed("event-container-bg");
      border: themed("main-border");
      box-shadow: themed("main-box-shadow");
    }

    .ui.divider.event-footer-divider {
      margin-bottom: 0px;
    }
  }
}

#sba-main-row {
  .column.transcription-navigation-wrapper {
    padding-bottom: 0px;
    padding-left: 0px;
  }
}

// LearningEventPage
.learning-event-body,
.learning-event-quiz {
  padding-left: 45px;
  padding-right: 45px;
}

// LearningEventHeader
.learning-event-header {
  @include themify($themes) {
    color: themed("event-header");
  }

  &.grid {
    padding: 20px 45px 0 45px;
  }

  .row.header-row {
    padding: 0;
  }
  .topic-title {
    font-size: 16px;
  }
  .header.event-title {
    font-size: 29px;

    @include themify($themes) {
      color: themed("event-header");
    }
  }

  .header.event-sub-title {
    font-size: 24px;
    font-weight: normal;
  }
  .event-meta {
    font-size: 16px;
    font-style: italic;
  }

  .header-button {
    margin-top: -9px !important;
    border-radius: 7px;

    @include themify($themes) {
      background-color: themed("event-header-button-bg");
      color: themed("event-header-button-color") !important;
      border: 2px solid themed("event-header-button-color");
    }

    &:hover,
    &:focus {
      @include themify($themes) {
        background-color: themed("event-header-button-focus-bg");
        border: 2px solid themed("event-header-button-focus-border");
      }
    }
  }
}

// LearningEventFooter
.ui.grid.learning-event-footer {
  padding-top: 0;
  padding-left: 26px;
  padding-right: 30px;

  .learning-event-navigation-wrapper {
    text-align: right;
  }
}

// LearningEventPodcast
.podcast-container {
  padding: 35px 1em 0 1em;

  .controls-container {
    display: flex;
    max-width: 100%;

    .button:hover,
    .button:focus {
      text-decoration: none;
    }

    .button:focus {
      @include themify($themes) {
        border: solid 2px themed("event-podcast-container-button-focus");
      }
    }

    .controls-left {
      width: 100px;
      flex: 0 0 100px;

      .ui.massive.button {
        width: 74px;
        height: 74px;

        i.play {
          padding-top: 3px;
          padding-left: 5px;
        }

        i.pause {
          padding-top: 3px;
          padding-left: 1px;
        }
      }
    }

    .controls-right {
      flex: 1 0 0;

      .volume-controls {
        padding-left: 0;
      }
    }
  }

  .mobile-volume-control {
    display: none;
  }

  .podcast-author {
    font-size: 16px;
    line-height: 32px;
    margin: 0;
    padding: 0;
  }

  .podcast-controls {
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;
    overflow: hidden;
  }

  .podcast-duration {
    font-size: 16px;
    font-weight: normal;
    padding-right: 10px;
  }

  .podcast-duration-right {
    padding: 0 0 0 10px;
  }

  .ui.grid > .column:not(.row).mobile-duration {
    display: none;
  }

  .podcast-wrapper {
    display: none;
  }

  .ui.grid > .row.mobile-duration-slider {
    margin-top: 10px;
    padding: 2px;
  }

  .ui.grid > .row.mobile-duration {
    padding: 0;
  }

  h2.ui.header {
    font-size: 24px;
    font-weight: normal;
    line-height: 20px;
    margin: 0;
    padding: 0;
  }

  .slider {
    width: 80%;
    margin: 0 10px 0 10px;
    height: 12px;
  }
}

.tip-box {
  display: flex;

  @include themify($themes) {
    background-color: themed("event-tip-box-bg") !important;
    border: 1px solid themed("event-tip-box-border") !important;
  }

  .tip-title {
    @include themify($themes) {
      color: themed("event-tip-box-title-color");
    }
  }
}

.no-padding {
  padding: 0;
}

button.ui.gold {
  @include themify($themes) {
    color: themed("event-button-gold-bg");
  }
}

.question .ui.form > .field {
  margin: 0px;
}

.question > p {
  margin: 0 0 0.5em;
}

.inline-form {
  display: inline-block;
}

.learning-event-body {
  padding: 33px 45px;
  overflow: auto;
  width: 100%;

  div.content-block {
    overflow: hidden;

    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child {
      margin: calc(2rem - 0.14285714em) 0 1rem;
    }

    img.ui.floated.image,
    ui.medium.left.floated.image {
      margin-top: 30px;
    }

    p > img.ui.floated.image {
      margin-top: 10px;
    }
  }

  > h2 + p > img.ui.floated.image,
  p > img.ui.floated.left,
  p > img.ui.floated.image,
  h2 + img.ui.floated.left.image,
  img.ui.floated.left.paragraph-img,
  img.ui.floated.right.paragraph-img {
    margin-top: 10px;
  }

  > p:first-child > img.ui.floated.image,
  img.ui.floated.image.header-img {
    margin-top: 30px !important;
  }

  a {
    font-weight: bold;
  }

  p > small {
    line-height: 10px;
    > a {
      font-weight: normal;
    }
  }

  small {
    font-size: 14px;
  }

  blockquote {
    font-size: 22px;
    line-height: 1.5;
    font-style: italic;
    text-align: center;

    span.author {
      display: block;
      text-align: right;
      font-size: 19px;
      font-style: normal;
      font-weight: normal;
    }

    + div.content-block {
      h2:first-child {
        margin-top: 4px;
      }
    }
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;

    @include themify($themes) {
      color: themed("event-h3-color");
    }
  }

  h2.company-owner {
    margin-bottom: 0;
  }

  h3.company-subheader {
    margin-top: 0;
    font-weight: normal;

    @include themify($themes) {
      color: themed("event-h3-company-subheader-color");
    }
  }

  .ui.header.references-header {
    font-size: 24px;

    @include themify($themes) {
      color: themed("event-header-references-color");
    }
  }

  hr {
    margin: 33px 0;
  }

  hr + div.content-block > h2:first-child {
    margin-top: 0px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  img.ui.floated.image {
    margin: 40px;
  }

  img.ui.fluid.centered,
  img.ui.fluid.center {
    margin: 40px auto;
  }

  img.ui.floated.right.image {
    margin-right: 0;
  }

  img.ui.floated.left.image {
    margin-left: 0;
  }

  ul,
  ol,
  div.ui.message {
    font-size: 16px;
  }

  ul,
  ol {
    &.maintain-margin {
      display: block;
      position: relative;
      left: 1em;
      top: 0;
    }

    &.space-between-items > li {
      margin: 0 0 1.5em 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  td > ul {
    padding-inline-start: 20px;
  }

  ol.with-bottom-margins,
  ul.with-bottom-margins {
    & > li {
      padding-bottom: 10px;
    }
  }

  .references {
    h6,
    .references-html {
      font-size: 16px !important;
      > a {
        font-weight: normal;
      }
    }

    .ui.divider {
      margin: 33px 0;
    }

    h6 {
      font-size: 24px;
      margin: 33px 0;
    }
  }

  table.path-content-table {
    thead {
      @include themify($themes) {
        color: themed("event-path-content-table-color");
        background-color: themed("event-path-content-table-bg");
      }

      th {
        padding: 0 5px;
      }
    }

    th {
      @include themify($themes) {
        color: themed("event-path-content-table-color");
        background-color: themed("event-path-content-table-bg");
      }
      padding: 0 5px;
    }


    tbody {
      td {
        padding: 0 5px;
      }
      tr:nth-child(even) {
        @include themify($themes) {
          background-color: themed("event-path-content-table-tr-even");
        }
      }
    }
  }
}

@media only screen and (max-width: 1580px) {
  #sba-main-row {
    .podcast-container {
      .slider {
        width: 75%;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  #sba-main-row {
    .podcast-container {
      .slider {
        width: 70%;
      }
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  #sba-main-row {
    .duration-component {
      padding-right: 0px;
    }

    .learning-event-header {
      .download-button {
        margin-top: 0 !important;
      }
    }

    .podcast-container {
      .slider {
        width: 98%;
        margin: 0 5px 0 5px;
      }

      .volume-slider {
        display: none;
      }

      .desktop-duration {
        display: none;
      }

      .mobile-duration {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  // LearningEventFooter
  .ui.grid.learning-event-footer {
    padding-left: 0;
    padding-right: 0;
  }

  .learning-event-header.grid {
    padding: 20px 1em 0 1em;
  }

  #sba-main-row {
    .column.learning-event-navigation-wrapper {
      padding-left: 10px !important;
      padding-right: 10px !important;
      text-align: center;
    }

    .column.transcription-navigation-wrapper {
      padding-bottom: 0px;
    }

    .learning-event-header {
      @include themify($themes) {
        color: themed("event-header");
      }

      .ui.grid > .column {
        margin-bottom: 0px;

        h1 {
          margin: 0px;
        }
      }
      .row {
        padding: 0px;
      }

      .header.event-title {
        padding: 0px;
      }

      div.column {
        padding: 0px;
        margin: 0px;
      }
    }

    .podcast-container {
      .mobile-volume-control {
        text-align: right;
        display: block;
      }

      .volume-controls {
        display: none;
      }

      .podcast-duration {
        padding-right: 3px;

        .ui.grid > .row {
          margin-top: 0px;
        }
      }

      .ui.column.grid > [class*="four wide"].column {
        display: none !important;
      }

      .podcast-duration-right {
        padding-left: 0px;
      }

      .ui.massive.button {
        width: 64px;
        height: 64px;

        i.play {
          padding-top: 2px;
          padding-left: 5px;
        }

        i.pause {
          padding-top: 2px;
          padding-left: 1px;
        }
      }
    }
  }

  // LearningEventPage
  .learning-event-body,
  .learning-event-quiz {
    padding: 1em;

    a {
      word-wrap: break-word;
    }

    img.ui.floated.image {
      margin: 40px 0;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 631px) {
  .podcast-container {
    .ui.grid > [class*="twelve wide mobile"].column.podcast-info {
      width: 70% !important;
    }
  }
}

@media only screen and (max-width: $small-mobile-width) {
  #sba-main-row {
    .podcast-container {
      .ui.massive.button {
        width: 54px;
        height: 54px;
      }
    }
  }
}
