@import "../../assets/styles/base";

.titled-module {
  .title {
    font-weight: bold;
  }

  .row {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
