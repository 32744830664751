@import "../../assets/styles/base";

.sba-main-fixed {
    position: fixed !important;
}

@media screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
  #sba-main-column {
    padding: 0;
  }
}
