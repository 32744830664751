@import "../../assets/styles/base";

.journey-content {
  .journey-content-row {
    margin-top: 10px;
  }
}

.journey-resume {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @include themify($themes) {
    background-color: themed("journey-resume-background-color");
    box-shadow: 0px 1px 3px 0px themed("$box-shadow-color"),
      0px 0px 0px 1px themed("$box-shadow-color");
  }
}

.journey-resume-content {
  padding-top: 64px !important;
}

.journey-resume-image {
  padding-left: 0 !important;
}

.journey-item-header {
  @include themify($themes) {
    color: themed("journey-item-header-color") !important ;
  }
}

.journey-progress-bar-row {
  padding-bottom: 0 !important;
}

.journey-completed-row {
  padding-top: 0 !important;

  @include themify($themes) {
    color: themed("journey-completed-row-color");
  }
}

.divider-header {
  box-shadow: $box-shadow-two;
}

#journeys {
  padding-bottom: 80px;

  @include themify($themes) {
    background-color: themed("journeys-background-color");
  }

  .header {
    font-size: 1.625rem;
  }

  .header.journey-item-header {
    font-size: 1.5rem;
  }
}

#topic-content-view {
  padding-bottom: 5%;

  .topic-content-item-header {
    font-size: 1.5rem;
  }

  outline: none;
}

@media only screen and (max-width: $mobile-width) {
  #journey-content-grid {
    margin-top: 0;
    padding-top: 0;

    .journey-content-row {
      margin-top: 0;
    }
  }

  .ui.grid {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #sba-main-row > .column > .content-window > div.journey-content {
    padding: 0;
  }

  #sba-main-row .column.journey-resume-content {
    padding: 1rem !important;
  }

  .journey-progress.ui.grid {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
