@import "../../assets/styles/base";

.share-modal-button {
  margin: 0 5px 0 auto !important;
  float: right;
  cursor: pointer;
  white-space: pre;
  border-radius: 7px !important;

  @include themify($themes) {
    background-color: themed("share-modal-button-bg") !important;
    color: themed("share-modal-button-color") !important;
    border: 2px solid themed("share-modal-button-border") !important;
  }

  &:hover,
  &:focus {
    @include themify($themes) {
      background-color: themed("share-modal-button-hover-bg") !important;
      border: 2px solid themed("share-modal-button-hover-border") !important;
    }
  }

  .share-button-text:hover,
  .share-button-text:focus {
    text-decoration: underline;
  }
}

#share-modal {
  .share-modal-share {
    display: inline-block;
    margin-left: 6%;

    div {
      cursor: pointer;
    }

    p {
      margin-top: 5px;
      text-align: center;

      @include themify($themes) {
        color: themed("share-modal-p-color");
      }
    }
  }

  .share-method-group {
    margin-top: 20px;
  }

  div.share-method-column {
    text-align: center !important;
  }

  div.share-text-input > input {
    width: 324px !important;
    margin: 5px 5px 15px 0 !important;
    border-radius: 0 !important;
  }

  .share-copy-button {
    width: 100px;

    @include themify($themes) {
      background-color: themed("share-modal-share-copy-button-bg") !important;
      color: themed("share-modal-share-copy-button") !important;
    }
  }

  .share-facebook-icon {
    cursor: pointer;

    @include themify($themes) {
      color: themed("share-modal-facebook");
    }
  }

  .share-twitter-icon {
    cursor: pointer;

    @include themify($themes) {
      color: themed("share-modal-twitter");
    }
  }

  .share-linkedin-icon {
    cursor: pointer;

    @include themify($themes) {
      color: themed("share-modal-linkedin");
    }
  }
}
